export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const REGISTER_VISITOR_REQUEST = "REGISTER_VISITOR_REQUEST";
export const REGISTER_VISITOR_ERROR = "REGISTER_VISITOR_ERROR";
export const REGISTER_VISITOR_SUCCESS = "REGISTER_VISITOR_SUCCESS";

export const REGISTER_TRADER_REQUEST = "REGISTER_TRADER_REQUEST";
export const REGISTER_TRADER_REQUEST_ERROR = "REGISTER_TRADER_REQUEST_ERROR";
export const REGISTER_TRADER_REQUEST_SUCCESS = "REGISTER_TRADER_REQUEST_SUCCESS";

export const REGISTER_DEALER_REQUEST = "REGISTER_DEALER_REQUEST";
export const REGISTER_DEALER_REQUEST_ERROR = "REGISTER_DEALER_REQUEST_ERROR";
export const REGISTER_DEALER_REQUEST_SUCCESS = "REGISTER_DEALER_REQUEST_SUCCESS";

export const ACTIVATE_ACCOUNT_REQUEST = "ACTIVATE_ACCOUNT_REQUEST";
export const ACTIVATE_ACCOUNT_SUCCESS = "ACTIVATE_ACCOUNT_SUCCESS";
export const ACTIVATE_ACCOUNT_ERROR = "ACTIVATE_ACCOUNT_ERROR";

export const RESET_ACTIVATION_ERROR_ACTION = "RESET_ACTIVATION_ERROR_ACTION";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const CLOSE_NOTIFICATION_MODAL_REQUEST = "CLOSE_NOTIFICATION_MODAL_REQUEST";
export const CLOSE_NOTIFICATION_MODAL_SUCCESS = "CLOSE_NOTIFICATION_MODAL_SUCCESS";
export const CLOSE_NOTIFICATION_MODAL_FAILED = "CLOSE_NOTIFICATION_MODAL_FAILED";

export const TOGGLE_ADVERTISE_WB_MODAL_ACTION = "TOGGLE_ADVERTISE_WB_MODAL_ACTION";

export const GET_GOOGLE_CAPTCHA_KEY_REQUEST = "GET_GOOGLE_CAPTCHA_KEY_REQUEST";
export const GET_GOOGLE_CAPTCHA_KEY_SUCCESS = "GET_GOOGLE_CAPTCHA_KEY_SUCCESS";

export const SET_IS_COOKIES_ACCEPTED_ACTION = "SET_IS_COOKIES_ACCEPTED_ACTION";
