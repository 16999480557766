import * as types from "./profilesActionsTypes";


export const getAuthorizedUserProfileRequest = () => {
  return { type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST };
};

export const patchAuthorizedUserProfileRequest = (payload, callback) => {
  return { type: types.PATCH_AUTHORIZED_USER_PROFILE_REQUEST, payload, callback };
};

export const forgetPasswordRequest = (payload) => {
  return { type: types.FORGET_PASSWORD_REQUEST, payload };
};

export const resetPasswordRequest = (payload) => {
  return { type: types.RESET_PASSWORD_REQUEST, payload };
};

export const updatePasswordRequest = (payload) => {
  return { type: types.UPDATE_PASSWORD_REQUEST, payload };
};

export const getFavoriteItemsRequest = (payload) => {
  return { type: types.GET_FAVORITE_ITEMS_REQUEST, payload };
};

export const getFavoriteItemsStatisticsRequest = (payload) => {
  return { type: types.GET_FAVORITE_ITEMS_STATISTICS_REQUEST, payload };
};

export const getFavoriteDealersRequest = (payload) => {
  return { type: types.GET_FAVORITE_DEALERS_ITEMS_REQUEST, payload };
};

export const getFavoriteDealersStatisticsRequest = (payload) => {
  return { type: types.GET_FAVORITE_DEALERS_STATISTICS_REQUEST, payload };
};

export const addFavoriteItem = (payload, callback) => {
  return { type: types.ADD_FAVORITE_ITEM_REQUEST, payload, callback };
};

export const removeFavoriteItem = (payload) => {
  return { type: types.REMOVE_FAVORITE_ITEM_REQUEST, payload };
};

export const addFavoriteDealer = (payload) => {
  return { type: types.ADD_FAVORITE_DEALER_REQUEST, payload };
};

export const removeFavoriteDealer = (payload) => {
  return { type: types.REMOVE_FAVORITE_DEALER_REQUEST, payload };
};

export const removeFavoriteDealerFromDealerFavorites = (payload) => {
  return { type: types.REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_REQUEST, payload };
};

export const getCurrentDealer = payload => ({
  type: types.GET_CURRENT_DEALER_REQUEST,
  payload
});

export const editCurrentDealer = (payload, callBack = () => null) => ({
  type: types.EDIT_CURRENT_DEALER_REQUEST,
  payload,
  callBack
});

export const getCurrentDealerImages = payload => ({
  type: types.GET_CURRENT_DEALER_IMAGES_REQUEST,
  payload
});

export const editCurrentDealerImages = payload => ({
  type: types.EDIT_CURRENT_DEALER_IMAGES_REQUEST,
  payload
});

export const editCurrentDealerLogo = payload => ({
  type: types.EDIT_CURRENT_DEALER_LOGO_REQUEST,
  payload
});

export const getCurrentDealerAddressesRequest = payload => ({
  type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST,
  payload
});

export const createAddressesForCurrentDealerRequest = payload => ({
  type: types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST,
  payload
});

export const deleteAddressOfCurrentDealerRequest = payload => ({
  type: types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST,
  payload
});

export const patchAddressOfCurrentDealerRequest = payload => ({
  type: types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST,
  payload
});

export const getCurrentDealerStockImages = payload => ({
  type: types.GET_CURRENT_DEALER_STOCK_IMAGES_REQUEST,
  payload
});

export const deleteCurrentDealerStockImageRequest = payload => ({
  type: types.DELETE_CURRENT_DEALER_STOCK_IMAGE_REQUEST,
  payload
});

export const createCurrentDealerStockImageRequest = payload => ({
  type: types.CREATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST,
  payload
});

export const updateCurrentDealerStockImageRequest = payload => ({
  type: types.UPDATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST,
  payload
});

export const getAuthorizedDealerEmailEnquiriesForChartRequest = payload => ({
  type: types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST,
  payload
});

export const getDeliveryEnquiresForChartRequest = payload => ({
  type: types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_REQUEST,
  payload,
});

export const getAuthorizedDealerPhoneEnquiriesForChartRequest = payload => ({
  type: types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST,
  payload
});

export const getAuthorizedDealerCallbackEnquiriesForChartRequest = payload => ({
  type: types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST,
  payload
});

export const getCurrentDealerEmailEnquiriesRequest = payload => ({
  type: types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST,
  payload
});

export const getDeliveryEnquiresRequest = payload => ({
  type: types.GET_CURRENT_DEALER_DELIVERY_ENQUIRIES_REQUEST,
  payload
});

export const getCurrentDealerPhoneEnquiriesRequest = payload => ({
  type: types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST,
  payload
});

export const getCurrentDealerCallbackEnquiriesRequest = payload => ({
  type: types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST,
  payload
});

export const markEmailEnquiryAsSpamRequest = payload => ({
  type: types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST,
  payload
});

export const markEmailEnquiryAsReadRequest = payload => ({
  type: types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST,
  payload
});

export const markCallbackEnquiryAsReadRequest = payload => ({
  type: types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST,
  payload
});

export const addDealerSpeciality = payload => ({
  type: types.ADD_DEALER_SPECIALITY_REQUEST,
  payload
});

export const removeDealerSpeciality = payload => ({
  type: types.REMOVE_DEALER_SPECIALITY_REQUEST,
  payload
});

export const getDealerSpecialities = payload => ({
  type: types.GET_DEALER_SPECIALITIES_REQUEST,
  payload
});

export const setAntiqueImage = payload => ({
  type: types.SET_ANTIQUE_IMAGE_REQUEST,
  payload
});

export const setAntiqueImageSuccess = payload => ({
  type: types.SET_ANTIQUE_IMAGE_SUCCESS,
  payload
});

export const deleteAntiqueImage = payload => ({
  type: types.DELETE_ANTIQUE_IMAGE_REQUEST,
  payload
});

export const searchArtisans = payload => ({
  type: types.SEARCH_ARTISAN_REQUEST,
  payload
});

export const rotateAntiqueImage = payload => ({
  type: types.ROTATE_ANTIQUE_IMAGE_REQUEST,
  payload
});

export const getCurrencies = payload => ({
  type: types.GET_CURRENCIES_REQUEST,
  payload
});

export const clearSelectedAntique = () => ({
  type: types.CLEAR_SELECTED_ANTIQUE,
});

export const createItemRequest = (payload, history) => ({
  type: types.CREATE_ITEM_REQUEST,
  payload,
  history
});

export const editItemRequest = (payload, history) => ({
  type: types.EDIT_ITEM_REQUEST,
  payload,
  history
});

export const makeMainPhotoForAntique = payload => ({
  type: types.MAKE_MAIN_PHOTO_FOR_ANTIQUE,
  payload,
});

export const removeMainPhotoForAntique = payload => ({
  type: types.REMOVE_MAIN_PHOTO_FOR_ANTIQUE,
  payload,
});

export const closeSuccessModal = () => ({ type: types.CLOSE_SUCCESS_MODAL });

export const uploadNewImageForAuthorizedUserRequest = (payload) => ({
  type: types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_REQUEST,
  payload,
});

export const getAllEmailPreferencesRequest = (payload) => ({
  type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST, payload
});

export const getAllEmailPreferencesRequestError = (payload) => ({
  type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST_ERROR, payload,
});

export const getAllEmailPreferencesRequestSuccess = (payload) => ({
  type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST_SUCCESS, payload,
});

export const createNewEmailPreferenceRequest = (payload) => ({
  type: types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST, payload,
});

export const createNewEmailPreferenceRequestError = (payload) => ({
  type: types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST_ERROR, payload,
});

export const createNewEmailPreferenceRequestSuccess = (payload) => ({
  type: types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST_SUCCESS, payload,
});

export const deleteEmailPreferenceRequest = (payload, params) => ({
  type: types.DELETE_EMAIL_PREFERENCE_REQUEST, payload, params
});

export const deleteEmailPreferenceRequestError = (payload) => ({
  type: types.DELETE_EMAIL_PREFERENCE_REQUEST_ERROR, payload,
});

export const deleteEmailPreferenceRequestSuccess = (payload) => ({
  type: types.DELETE_EMAIL_PREFERENCE_REQUEST_SUCCESS, payload,
});

export const patchEmailPreferenceRequest = (payload) => ({
  type: types.PATCH_EMAIL_PREFERENCE_REQUEST, payload,
});

export const patchEmailPreferenceRequestError = (payload) => ({
  type: types.PATCH_EMAIL_PREFERENCE_REQUEST_ERROR, payload,
});

export const patchEmailPreferenceRequestSuccess = (payload) => ({
  type: types.PATCH_EMAIL_PREFERENCE_REQUEST_SUCCESS, payload,
});

export const getEmailPreferencesSearchResultRequest = (payload) => ({
  type: types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST, payload,
});

export const getEmailPreferencesSearchResultRequestError = (payload) => ({
  type: types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_ERROR, payload,
});

export const deleteItemRequest = (payload, history) => ({
  type: types.DELETE_ITEM_REQUEST,
  payload,
  history,
});

export const getEmailPreferencesSearchResultRequestSuccess = (payload) => ({
  type: types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_SUCCESS, payload,
});

export const getDealerInvoicesRequest = (payload) => ({
  type: types.GET_DEALER_INVOICES_REQUEST, payload,
});

export const getDealerInvoicesRequestError = (payload) => ({
  type: types.GET_DEALER_INVOICES_REQUEST_ERROR, payload,
});

export const getDealerInvoicesRequestSuccess = (payload) => ({
  type: types.GET_DEALER_INVOICES_REQUEST_SUCCESS, payload,
});

export const searchEmailEnquiriesRequest = (payload) => ({
  type: types.SEARCH_EMAIL_ENQUIRIES_REQUEST,
  payload,
});

export const searchEmailEnquiriesRequestError = (payload) => ({
  type: types.SEARCH_EMAIL_ENQUIRIES_REQUEST_ERROR,
  payload,
});

export const searchEmailEnquiriesRequestSuccess = (payload) => ({
  type: types.SEARCH_EMAIL_ENQUIRIES_REQUEST_SUCCESS,
  payload,
});

export const searchPhoneEnquiriesRequest = (payload) => ({
  type: types.SEARCH_PHONE_ENQUIRIES_REQUEST,
  payload,
});

export const searchPhoneEnquiriesRequestError = (payload) => ({
  type: types.SEARCH_PHONE_ENQUIRIES_REQUEST_ERROR,
  payload,
});

export const searchPhoneEnquiriesRequestSuccess = (payload) => ({
  type: types.SEARCH_PHONE_ENQUIRIES_REQUEST_SUCCESS,
  payload,
});

export const searchCallbackEnquiriesRequest = (payload) => ({
  type: types.SEARCH_CALLBACK_ENQUIRIES_REQUEST,
  payload,
});

export const searchCallbackEnquiriesRequestError = (payload) => ({
  type: types.SEARCH_CALLBACK_ENQUIRIES_REQUEST_ERROR,
  payload,
});

export const searchCallbackEnquiriesRequestSuccess = (payload) => ({
  type: types.SEARCH_CALLBACK_ENQUIRIES_REQUEST_SUCCESS,
  payload,
});

export const searchDeliveryEnquiriesRequest = (payload) => ({
  type: types.SEARCH_DELIVERY_ENQUIRIES_REQUEST,
  payload,
});

export const searchDeliveryEnquiriesRequestSuccess = (payload) => ({
  type: types.SEARCH_DELIVERY_ENQUIRIES_REQUEST_SUCCESS,
  payload,
});

export const searchDeliveryEnquiriesRequestError = (payload) => ({
  type: types.SEARCH_DELIVERY_ENQUIRIES_REQUEST_ERROR,
  payload,
});


export const createDefaultTextRequest = (payload) => ({
  type: types.CREATE_DEFAULT_TEXT_REQUEST,
  payload,
});

export const getAllDefaultTextsRequest = () => ({
  type: types.GET_ALL_DEFAULT_TEXTS_REQUEST,
});

export const deleteDefaultTextRequest = (payload) => ({
  type: types.DELETE_DEFAULT_TEXT_REQUEST,
  payload
});

export const getSingleDefaultTextRequest = (payload) => ({
  type: types.GET_SINGLE_DEALER_TEXT_REQUEST,
  payload,
});

export const clearSelectedDefaultTextData = () => ({
  type: types.CLEAR_SELECTED_DEFAULT_TEXT,
});

export const patchDefaultTextRequest = (payload) => ({
  type: types.PATCH_DEFAULT_TEXT_REQUEST,
  payload,
});

export const setNewImagesOrder = (payload) => ({
  type: types.SET_NEW_IMAGES_ORDER,
  payload
});

export const createDealerWebsiteRequest = (payload, callBack) => ({
  type: types.CREATE_DEALER_WEBSITE_REQUEST,
  payload,
  callBack
});

export const updateDealerWebsiteRequest = (payload, callBack) => ({
  type: types.UPDATE_DEALER_WEBSITE_REQUEST,
  payload,
  callBack
});

export const getDealerWebsiteRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_REQUEST,
  payload
});

export const createDealerWebsiteInfoRequest = (payload, callBack) => ({
  type: types.CREATE_DEALER_WEBSITE_INFO_REQUEST,
  payload,
  callBack
});

export const updateDealerWebsiteInfoRequest = (payload, callBack) => ({
  type: types.UPDATE_DEALER_WEBSITE_INFO_REQUEST,
  payload,
  callBack
});

export const getDealerWebsiteInfoRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_INFO_REQUEST,
  payload
});

export const sendDealerWebsiteDomainRequest = (payload, callBack) => ({
  type: types.SEND_DEALER_WEBSITE_DOMAIN_REQUEST,
  payload,
  callBack
});

export const createDealerWebsitePageRequest = (payload, callBack) => ({
  type: types.CREATE_DEALER_WEBSITE_PAGE_REQUEST,
  payload,
  callBack
});

export const updateDealerWebsitePageRequest = (payload, callBack) => ({
  type: types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST,
  payload,
  callBack
});

export const updateDealerWebsiteBlogRequest = (payload, callBack) => ({
  type: types.UPDATE_DEALER_WEBSITE_BLOG_REQUEST,
  payload,
  callBack
});

export const getDealerWebsitePageRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_PAGE_REQUEST,
  payload
});

export const deleteDealerWebsitePageRequest = (payload) => ({
  type: types.DELETE_DEALER_WEBSITE_PAGE_REQUEST,
  payload
});

export const clearDealerWebsitePageAction = () => ({
  type: types.CLEAR_DEALER_WEBSITE_PAGE,
});

export const getDealerWebsitePagesListRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST,
  payload
});

export const getDealerWebsiteBlogsListRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST,
  payload
});

export const publishDealerWebsiteRequest = (payload, callBack) => ({
  type: types.PUBLISH_DEALER_WEBSITE_REQUEST,
  payload,
  callBack
});

export const publishDeclinedDealerWebsiteRequest = (payload) => ({
  type: types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST,
  payload
});

export const unpublishDealerWebsiteRequest = (payload) => ({
  type: types.UNPUBLISH_DEALER_WEBSITE_REQUEST,
  payload,
});

export const getDealerWebsiteSubscriptionsRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST,
  payload
});

export const deleteDealerWebsiteSubscriptionRequest = (payload) => ({
  type: types.DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST,
  payload
});

export const updateDealerWebsiteSubscriptionRequest = (payload) => ({
  type: types.UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST,
  payload
});

export const exportDealerWebsiteSubscriptionsRequest = (payload) => ({
  type: types.EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST,
  payload
});

export const setDealerWebsitePagesAction = (payload) => ({
  type: types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_SUCCESS,
  payload
});

export const setDealerWebsiteBlogsAction = (payload) => ({
  type: types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_SUCCESS,
  payload
});

export const clearDataError = () => ({
  type: types.CLEAR_DATA_ERROR,
});

export const getMarketplacesRequest = (payload) => ({
  type: types.GET_MARKETPLACES_REQUEST,
  payload
});

export const connectMarketplaceRequest = (payload) => ({
  type: types.CONNECT_MARKETPLACE_REQUEST,
  payload
});

export const getSuggestedCategoriesRequest = (payload, callback) => ({
  type: types.GET_SUGGESTED_CATEGORIES_REQUEST,
  payload,
  callback
})

export const getHolidayModeDetailsRequest = (payload) => ({
  type: types.GET_HOLIDAY_MODE_DETAILS_REQUEST,
  payload
});

export const modifyHolidayModeDetailsRequest = (payload) => ({
  type: types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST,
  payload
});

export const getTradeAssociationsRequest = (payload) => ({
  type: types.GET_TRADE_ASSOCIATIONS_REQUEST,
  payload
});

export const createDealerTradeAssociationsRequest = (payload, callback) => ({
  type: types.CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST,
  payload,
  callback
});

export const getDealerTradeAssociationsRequest = (payload) => ({
  type: types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST,
  payload
});

export const clearDealerTradeAssociation = (payload) => ({
  type: types.CLEAR_DEALER_TRADE_ASSOCIATIONS,
  payload
});

export const getOnlineSalesStatisticsRequest = (payload) => ({
  type: types.GET_ONLINE_SALES_STATISTICS_REQUEST,
  payload
});
