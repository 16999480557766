import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import RouteWithStatus from "./components/RouteWithStatus";

import { RedirectToDealers } from "../Dealers/RedirectToDealers";
import { RedirectToDealerSinglePage } from "../DealerSinglePage/RedirectToDealerSinglePage";

const NotFound = loadable(() => import("../NotFound/NotFound"));
const AuthRoutes = loadable(() => import("./AuthRoutes"));
const UserRoutes = loadable(() => import("./UserRoutes"), { ssr: false });
const RegistrationRoutes = loadable(() => import("./RegistrationRoutes"));
const ItemsRoutes = loadable(() => import("./ItemsRoutes"));
const ThankYouPage = loadable(() => import("../ThankYouPage"));
const SocialAuthPage = loadable(() => import("../Auth/SocialAuth/SocialAuthPage"));
const JoinAsTrader = loadable(() => import("../Auth/Registration/JoinAsTrader"));

const BusinessRoutes = loadable(() => import("../../app-store/pages/BusinessRoutes"));
const Home = loadable(() => import("../Home/Home"));
const DealersRoutes = loadable(() => import("../Dealers/DealersRoutes"));
const CategoryPage = loadable(() => import("../CategoryPage/CategoryPage"));
const FAQPage = loadable(() => import("../FAQ/FAQ"));
const PressOffice = loadable(() => import("../ContactUs/PressOffice"));
const ContactUs = loadable(() => import("../ContactUs/ContactUs"));
const Knowledge = loadable(() => import("../Knowledge/Knowledge"));
const DealerTestimonials = loadable(() => import("../DealerTestimonials/DealerTestimonials"));
const MainMaterial = loadable(() => import("../Material"));
const MainOrigin = loadable(() => import("../Origin"));
const PeriodMain = loadable(() => import("../Period"));
const Artisans = loadable(() => import("../Artisans"));
const BlogPage = loadable(() => import("../Blog/Blog"));
const AboutUs = loadable(() => import("../AboutUs/AboutUs"));
const TermsAndConditions = loadable(() => import("../TermsAndConditions/TermsAndConditions"));
const PrivacyPolicy = loadable(() => import("../PrivacyPolicy/PrivacyPolicy"));
const HomeAndLivingMain = loadable(() => import("../HomeAndLiving/HomeAndLivingMain"));
const HomeAndLivingCategory = loadable(() => import("../HomeAndLiving/HomeAndLivingCategory"));
const BuyItem = loadable(() => import("../ItemPage/BuyItem"));
const UnregisteredFavourites = loadable(() => import("../UnregisteredFavourites/UnregisteredFavourites"));
const WelcomePage = loadable(() => import("../WelcomePage/WelcomePage"));
const EmailUnsubscribe = loadable(() => import("../EmailUnsubscribe/EmailUnsubscribe"));
const SearchResults = loadable(() => import("../shared/SearchResults"));
const WebsiteBuilderWelcomePage = loadable(() => import("../DealerProfile/WebSite/WebsiteBuilderWelcomePage"));
const Sitemap = loadable(() => import("../Sitemap"));

function AppRoutes() {
  return (
    <Switch>
      <PublicRoute path="/auth" component={AuthRoutes} />
      <PublicRoute path="/registration" component={RegistrationRoutes} />
      <PublicRoute path="/invitation" component={JoinAsTrader} />
      <PublicRoute path="/social-google" component={SocialAuthPage} />
      <PublicRoute path="/social-facebook" component={SocialAuthPage} />

      <PublicRoute path="/favorites/items" component={UnregisteredFavourites} />

      <PrivateRoute path="/profile" component={UserRoutes} />

      <RouteWithStatus path="/not-found" status={404} component={NotFound} />

      <Route path="/" exact component={Home} />
      <Route path="/items" component={ItemsRoutes} />
      <Route exact path="/thank-you" component={ThankYouPage} />
      <Route exact path="/faq" component={FAQPage} />
      <Route exact path="/press-office" component={PressOffice} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
      <Route exact path="/dealer-testimonials" component={DealerTestimonials} />
      <Route exact path="/unsubscribe" component={EmailUnsubscribe} />
      <Route exact path="/sitemap" component={Sitemap} />
      <Route exact path="/home-and-living" component={HomeAndLivingMain} />
      <Route exact path="/home-and-living/:categoryURL" component={HomeAndLivingCategory} />
      <Route path="/directory/dealers" component={DealersRoutes} />
      <Route path="/directory" component={BusinessRoutes} />
      {/*TODO(LANT-5450): Add redirect to dealers list and single dealer pages*/}
      <Route exact path="/knowledge/dealers" component={RedirectToDealers} />
      <Route exact path="/knowledge/dealers/:url" component={RedirectToDealerSinglePage} />
      <Route path="/knowledge" exact component={Knowledge} />
      <Route path="/knowledge/material" component={MainMaterial} />
      <Route path="/knowledge/artisan" component={Artisans} />
      <Route path="/knowledge/origin" component={MainOrigin} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/period" component={PeriodMain} />
      <Route path="/blog" component={BlogPage} />
      <Route path="/checkout/:url" component={BuyItem} />
      <Route exact path="/welcome-page" component={WelcomePage} />
      <Route path="/search-results" component={SearchResults} />
      <Route exact path="/web-design-for-antiques-dealers" component={WebsiteBuilderWelcomePage} />

      {/*Don't put any routes below*/}
      <Route
        path="/:categoryUrl/:seoCategory1/:seoCategory2"
        render={props => <CategoryPage {...props} key={props.location.pathname} />}
      />
      <Route
        path="/:categoryUrl/:seoCategory1"
        render={props => <CategoryPage {...props} key={props.location.pathname} />}
      />
      <Route
        path="/:categoryUrl/"
        render={props => <CategoryPage {...props} key={props.location.pathname} />}
      />
    </Switch>
  );
}

AppRoutes.propTypes = {
  location: PropTypes.object
};

export default AppRoutes;
