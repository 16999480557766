import React, { useEffect, useState } from "react";
import { Button, message, Modal, Popconfirm } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import profilesAPI from "../../api/profiles";
import { toggleAdvertiseWBModalAction } from "../../redux/auth/authActions";
import { MESSAGE_TRY_AGAIN } from "../../constants";
import "./AdvertiseWBModal.scss";

const MODALS = [
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-1.png`,
    title: "Dealer websites now available from LoveAntiques.com",
    text: "Try our exclusive Website Builder feature. We have multiple customisable templates for you to choose " +
      "from, with features including a text logo creation tool, a fonts library, a gallery and " +
      "lots more to help your website stand out.",
    button: "Try It Out"
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-2.png`,
    title: "Upload stock to your Dealer website and LoveAntiques.com simultaneously",
    text: "Save yourself time and effort by sharing your stock to your website and LoveAntiques.com with the click " +
      "of a button. As well as this, you can connect your stock to various other platforms with ease, " +
      "using our marketplace connections feature.",
    button: "Open Website Builder"
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-3.png`,
    title: "Multiple Dealer website templates to choose from",
    text: "LoveAntiques.com offers free design and website builds with many templates and palettes to choose from.",
    button: "Create Your Website"
  },
  {
    image: `${process.env.REACT_APP_URL}/images/advertise-website-builder-banner-4.png`,
    title: "Search Engine Optimised websites from LoveAntiques.com",
    text: "Give your business the best chance of ranking in search consoles; LoveAntiques.com fully optimises " +
      "its dealer websites generating more traffic for your business.",
    button: "Create Your Website"
  }
];

const AdvertiseWBModal = ({ authorizedUserProfile, isAdvertiseWBModalOpened, toggleAdvertiseWBModal }) => {
  const MODAL_PROPS = MODALS[Math.floor(Math.random() * 4)];

  const closeAdvertiseWBModal = () => {
    sessionStorage.removeItem("advertiseWebsiteBuilder");
    toggleAdvertiseWBModal(false);
  };

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

  useEffect(() => {
    if (authorizedUserProfile?.id && sessionStorage.getItem("advertiseWebsiteBuilder") === "true") {
      toggleAdvertiseWBModal(true);
    }
  }, [authorizedUserProfile]);

  useEffect(() => {
    return () => closeAdvertiseWBModal();
  }, []);

  const requestAccess = () => {
    profilesAPI.requestDealerSiteBuilderAccess()
      .then(() => {
        closeAdvertiseWBModal();
        setIsSuccessModalOpened(true);
      })
      .catch(() => message.error(MESSAGE_TRY_AGAIN))
      .finally(closeAdvertiseWBModal);
  };

  return (
    <>
      <Modal
        open={isAdvertiseWBModalOpened}
        centered
        okText={MODAL_PROPS.button}
        onCancel={closeAdvertiseWBModal}
        footer={null}
        width={640}
        className="advertise-modal"
      >
        <img src={MODAL_PROPS.image} className="advertise-modal--image" alt="" />
        <div className="advertise-modal--content">
          <div className="advertise-modal--title">{MODAL_PROPS.title}</div>
          <div className="advertise-modal--text">{MODAL_PROPS.text}</div>
          <div className="flex justify-between">
            <Button
              size="large"
              className="ant-btn-default advertise-modal--btn header--auth-button--logout"
              onClick={closeAdvertiseWBModal}
            >
              Close
            </Button>
            <Popconfirm
              placement="top"
              title="Are you sure you want to send request for website builder?"
              okText="Yes"
              cancelText="No"
              onConfirm={requestAccess}
            >
              <Button size="large" className="submit-btn advertise-modal--btn">
                {MODAL_PROPS.button}
              </Button>
            </Popconfirm>
          </div>
        </div>
      </Modal>
      <Modal
        open={isSuccessModalOpened}
        wrapClassName="advertise-modal--success"
        centered
        onCancel={() => setIsSuccessModalOpened(false)}
        footer={null}
      >
        <div className="callback-modal--body" data-testid="lant-ask-question-modal-form">
          <div className="callback-modal--body-header">
            <img src={`${process.env.REACT_APP_IMAGES_URL}loginLALogo.svg`} alt="LoveAntiques" title="LoveAntiques" />
          </div>
          <div className="callback-modal--body-main">
            <h2 className="h2" style={{ textAlign: "center" }}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5741 10.4091H20.9253C20.5667 10.4091 20.2257 10.5813 20.0147 10.8766L14.4882 18.5407L11.9851 15.0673C11.7741 14.7755 11.4366 14.5997 11.0745 14.5997H9.42568C9.19716 14.5997 9.06357 14.8598 9.19716 15.0462L13.5776 21.1212C13.6811 21.2656 13.8175 21.3833 13.9756 21.4645C14.1336 21.5457 14.3087 21.588 14.4864 21.588C14.6641 21.588 14.8392 21.5457 14.9973 21.4645C15.1553 21.3833 15.2917 21.2656 15.3952 21.1212L22.7991 10.8555C22.9362 10.6692 22.8026 10.4091 22.5741 10.4091Z" fill="#004F57"/>
                <path d="M16 0.25C7.30234 0.25 0.25 7.30234 0.25 16C0.25 24.6977 7.30234 31.75 16 31.75C24.6977 31.75 31.75 24.6977 31.75 16C31.75 7.30234 24.6977 0.25 16 0.25ZM16 29.0781C8.77891 29.0781 2.92188 23.2211 2.92188 16C2.92188 8.77891 8.77891 2.92188 16 2.92188C23.2211 2.92188 29.0781 8.77891 29.0781 16C29.0781 23.2211 23.2211 29.0781 16 29.0781Z" fill="#004F57"/>
              </svg>
              <br />
              Thank you for your interest
              <br />
              in Website builder.
            </h2>
            <div
              style={{
                width: "100%",
                marginTop: "24px",
                textAlign: "center",
                fontWeight: 600
              }}
            >
              Your website creation feature will be enabled soon.
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

AdvertiseWBModal.propTypes = {
  isAdvertiseWBModalOpened: PropTypes.bool,
  toggleAdvertiseWBModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    authorizedUserProfile: state.auth.authorizedUserProfile,
    isAdvertiseWBModalOpened: state.auth.isAdvertiseWBModalOpened,
  }),
  {
    toggleAdvertiseWBModal: toggleAdvertiseWBModalAction,
  }
)(AdvertiseWBModal);
