export const NORTHERN_IRELAND_OPTIONS = [
  {
    place_id: null,
    county: "Antrim",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "antrim",
    latitude: 54.83234,
    longitude: -6.38306,
    boundingBox: [9.12964, 55.46017, -3.63647, 54.19458],
    zoom: 8
  },
  {
    place_id: null,
    county: "Armagh",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "armagh",
    latitude: 54.30210,
    longitude: -6.58356,
    boundingBox: [-7.95685, 54.62139, -5.21027, 53.98032],
    zoom: 9
  },
  {
    place_id: null,
    county: "City of Belfast",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "city-of-belfast",
    latitude: 54.59639,
    longitude: -5.93018,
    boundingBox: [-6.61652, 54.75554, -5.24323, 54.43730],
    zoom: 10
  },
  {
    place_id: null,
    county: "Down",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "down",
    latitude: 54.36136,
    longitude: -5.91614,
    boundingBox: [-8.66272, 54.99652, -3.16956, 53.71622],
    zoom: 8
  },
  {
    place_id: null,
    county: "Fermanagh",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "fermanagh",
    latitude: 54.36296,
    longitude: -7.66022,
    boundingBox: [-9.03351, 54.68177, -6.28693, 54.04165],
    zoom: 9
  },
  {
    place_id: null,
    county: "Londonderry",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "londonderry",
    latitude: 54.77376,
    longitude: -6.39267,
    boundingBox: [-8.11890, 55.23685, -4.66644, 54.30531],
    zoom: 9
  },
  {
    place_id: null,
    county: "City of Derry",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "city-of-derry",
    zoom: 8
  },
  {
    place_id: null,
    county: "Tyrone",
    state_district: null,
    state: "Northern Ireland",
    country_code: "gb",
    url: "tyrone",
    latitude: 54.63650,
    longitude: -7.16374,
    boundingBox: [-8.53638, 54.95396, -5.78979, 54.31813],
    zoom: 9
  },
];
