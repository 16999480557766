// helpers
import { objectToQueryString } from "../helpers";
import fetch from "./customFetch";

const PROFILES_DEALER_DETAILS = "/profiles/dealer/details/";
const MULTIPART_FORM_DATA = "multipart/form-data";

const profiles = {
  async getAuthorizedUserProfile() {
    try {
      return await fetch({
        url: "/profiles/",
        method: "GET",
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async patchAuthorizedUserProfile(data) {
    try {
      return await fetch({
        url: "/profiles/",
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async forgetPassword(data) {
    try {
      return await fetch({
        url: "/profiles/password/forget/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err.data && err.data.nonFieldErrors) {
        throw err.data.nonFieldErrors[0];
      }
      throw err;
    }
  },

  async resetPassword(data) {
    try {
      return await fetch({
        url: "/profiles/password/reset/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async updatePassword(data) {
    try {
      return await fetch({
        url: "/profiles/password/update/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async getFavoriteItems({ fromDate, toDate, statistic, ...rest }) {
    const query = objectToQueryString({
      from_date: fromDate,
      to_date: toDate,
      ...rest,
    });

    try {
      return await fetch({
        url: `/profiles/favorite-items${
          statistic ? "/favourite-item-statistic" : ""
        }/?${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getFavoriteDealers({ fromDate, toDate, statistic, prePage, ...rest }) {
    const query = objectToQueryString({
      from_date: fromDate,
      to_date: toDate,
      page_size: prePage,
      ...rest,
    });

    try {
      return await fetch({
        url: `/profiles/favorite-dealers${
          statistic ? "/favourite-dealer-statistic" : ""
        }/?${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async addFavoriteItem(data) {
    try {
      return await fetch({
        url: "/profiles/favorite-items/add/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async addFavoriteDealer(data) {
    try {
      return await fetch({
        url: "/profiles/favorite-dealers/add/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteFavoriteDealer(data) {
    try {
      return await fetch({
        url: "/profiles/favorite-dealers/delete/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerDetails() {
    try {
      return await fetch({
        url: PROFILES_DEALER_DETAILS,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async postReferFriend(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/refer-a-friend/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchDealerDetails(data) {
    try {
      return await fetch({
        url: PROFILES_DEALER_DETAILS,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async putDealerDetails(data) {
    try {
      return await fetch({
        url: PROFILES_DEALER_DETAILS,
        method: "PUT",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerImages() {
    try {
      return await fetch({
        url: "/profiles/dealer/images/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchDealerImages(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/images/",
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerAddressesApi({ page = 1, pageSize = 10 }) {
    try {
      return await fetch({
        url: `/profiles/dealer/addresses/?page=${page}&page_size=${pageSize}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createAddressesForCurrentDealer(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/addresses/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchAddressOfCurrentDealer(data) {
    try {
      return await fetch({
        url: `/profiles/dealer/addresses/${data.id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteAddressOfCurrentDealer(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/addresses/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerStockImages() {
    try {
      return await fetch({
        url: "/profiles/dealer/stock-images/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createCurrentDealerStockImage(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/stock-images/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async updateCurrentDealerStockImage(data) {
    try {
      return await fetch({
        url: `/profiles/dealer/stock-images/${data.id}/`,
        method: "PUT",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteCurrentDealerStockImage(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/stock-images/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async addDealerSpeciality(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/specialities/add/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerEmailEnquiriesRequest({
    page = 1,
    pageSize = 5,
    toDate,
    fromDate,
  }) {
    const query = new URLSearchParams();

    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/email/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerPhoneEnquiriesRequest({
    page = 1,
    pageSize = 5,
    toDate,
    fromDate,
  }) {
    const query = new URLSearchParams();

    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/phone/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerDeliveryEnquiriesRequest({
    page = 1,
    pageSize = 5,
    toDate,
    fromDate,
  }) {
    const query = new URLSearchParams();

    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/delivery-quote/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getCurrentDealerCallbackEnquiriesRequest({
    page = 1,
    pageSize = 5,
    toDate,
    fromDate,
  }) {
    const query = new URLSearchParams();

    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/dealer/enquiries/callback/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async removeDealerSpeciality(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/specialities/delete/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async markEmailEnquiryAsSpam(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/enquiries/email/spam/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async markEmailEnquiryAsRead(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/enquiries/email/read/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async markDeliveryEnquiryAsRead(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/enquiries/delivery-quote/read/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async markCallbackEnquiryAsRead(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/enquiries/callback/read/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createItem(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/items/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async editItem({ id, data }) {
    try {
      return await fetch({
        url: `/profiles/dealer/items/${id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteItem(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/items/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAllEmailPreferences({ page = 1, pageSize = 10 }) {
    const query = new URLSearchParams();

    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/email-preferences/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createNewEmailPreference(data) {
    try {
      return await fetch({
        url: "/profiles/email-preferences/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createNewEmailPreferenceAnonymous(data) {
    try {
      return await fetch({
        url: "/profiles/email-preferences/anonymous/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteNewEmailPreferenceAnonymous(query) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/anonymous/unsubscribe/${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteEmailPreference(id) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchEmailPreference({ id, data }) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/${id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getEmailPreferencesSearchResult({ id, page = 1, pageSize = 12 }) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/${id}/items/?page=${page}&page_size=${pageSize}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAuthorizedDealerInvoices({
    status,
    page = 1,
    pageSize = 10,
    fromDate,
    toDate,
  }) {
    const query = new URLSearchParams();

    query.set("page", page);
    query.set("page_size", pageSize);
    if (status) {
      query.set("status", status);
    }
    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }

    try {
      return await fetch({
        url: `/profiles/dealer/invoices/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getInvoicePDF(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/invoices/${id}/generate-pdf/`,
        method: "GET",
        responseType: "blob",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDefaultText(data) {
    const dataToSend = {
      type: data.type,
      title: data.title,
      text: data.text,
    };

    try {
      return await fetch({
        url: "/profiles/dealer/default-texts/",
        method: "POST",
        data: dataToSend,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getAllDefaultTexts() {
    try {
      return await fetch({
        url: `/profiles/dealer/default-texts/?page=${1}&page_size=${16}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteDefaultText(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/default-texts/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getSingleDefaultText(id) {
    try {
      return await fetch({
        url: `/profiles/dealer/default-texts/${id}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async patchDefaultText(data) {
    const dataToSend = {
      type: data.type,
      title: data.title,
      text: data.text,
    };

    try {
      return await fetch({
        url: `/profiles/dealer/default-texts/${data.id}/`,
        method: "PATCH",
        data: dataToSend,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDealerWebsite(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/sites/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async updateDealerWebsite(data) {
    try {
      return await fetch({
        url: `/dealer_site_builder/sites/${data.id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsite(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/sites/",
        method: "GET",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDealerWebsiteInfo(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/site-info/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async updateDealerWebsiteInfo(data) {
    try {
      return await fetch({
        url: `/dealer_site_builder/site-info/${data.id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsiteInfo(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/site-info/",
        method: "GET",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async sendDealerWebsiteDomainRequest(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/domain-request/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDealerWebsitePage(data, pageType) {
    try {
      return await fetch({
        url: `/dealer_site_builder/${pageType}/`,
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async updateDealerWebsitePage(data, pageType, isSales) {
    try {
      return await fetch({
        url: `/dealer_site_builder/${pageType}/${
          data.id
        }/?is_sales=${!!isSales}`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsitePage(data, pageType, isSales) {
    try {
      return await fetch({
        url: `/dealer_site_builder/${pageType}/${
          data.id
        }/?is_sales=${!!isSales}`,
        method: "GET",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteDealerWebsitePage(data, pageType) {
    try {
      return await fetch({
        url: `/dealer_site_builder/${pageType}/${data.id}/`,
        method: "DELETE",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsitePagesList({ isSales, isBlog, isGallery }) {
    const query = new URLSearchParams();
    query.set("is_sales", isSales);
    query.set("is_blog", isBlog);
    query.set("is_gallery", isGallery);

    try {
      return await fetch({
        url: `/dealer_site_builder/pages/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsiteBlogsList() {
    try {
      return await fetch({
        url: "/dealer_site_builder/dealers/blog-pages/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async publishDealerWebsite(data) {
    try {
      return await fetch({
        url: "/dealer_site_builder/publish/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async publishDeclinedDealerWebsite({ id }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/sites/${id}/republish/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async unpublishDealerWebsite({ id }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/sites/${id}/unpublish/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDefaultMeasurements() {
    try {
      return await fetch({
        url: "/profiles/dealer/default-measurements/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDefaultMeasurements(data) {
    try {
      return await fetch({
        url: "/profiles/dealer/default-measurements/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async editDefaultMeasurements(data, id) {
    try {
      return await fetch({
        url: `/profiles/dealer/default-measurements/${id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerWebsiteSubscriptions({ page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/dealer_site_builder/dealer/subscribers/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteDealerWebsiteSubscription(id) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealer/subscribers/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async updateDealerWebsiteSubscription(data) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealer/subscribers/${data.id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async exportDealerWebsiteSubscriptions() {
    try {
      return await fetch({
        url: "/dealer_site_builder/dealer/subscribers/export/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getMarketplaces({ isConnected }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealer/market-places/?isConnected=${isConnected}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async storeEbaySessionId(sessionId) {
    try {
      return await fetch({
        url: `/ebay/token/?session_id=${encodeURIComponent(sessionId)}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async storeFacebookToken(code) {
    try {
      return await fetch({
        url: `/facebook/token/?code=${code}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
  async storeInstagramToken({ data }) {
    try {
      return await fetch({
        url: "/instagram/token/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async connectMarketplace({ id, data }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealer/market-places/${id}/`,
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getImpressionsStatistics(dealerUrl) {
    try {
      return await fetch({
        url: `/profiles/dealer/stats/${dealerUrl}/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async sendWebsiteBuilderPreviewViaEmail(data) {
    try {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("recipient", data.recipient);
      formData.append("message", data.message);
      formData.append("cc", data.cc);

      return fetch({
        url: "/dealer_site_builder/sites/share-preview/",
        method: "POST",
        data: formData,
        headers: { "Content-Type": MULTIPART_FORM_DATA },
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getStripeLink() {
    try {
      return fetch({
        url: "/profiles/create-stripe-account/",
        method: "POST",
        headers: { "Content-Type": "MULTIPART_FORM_DATA" },
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async checkVerifyStripeAccount() {
    try {
      return fetch({
        url: "/profiles/verify-stripe-account/",
        method: "POST",
        headers: { "Content-Type": "MULTIPART_FORM_DATA" },
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getStripeLoginLink() {
    try {
      return fetch({
        url: "/profiles/stripe-login-link",
        method: "POST",
        headers: { "Content-Type": "MULTIPART_FORM_DATA" },
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async disconnectStripeAccount() {
    try {
      return fetch({
        url: "/profiles/disconnect-stripe-account/",
        method: "POST",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getSuggestedCategory(itemTitle) {
    try {
      return fetch({
        url: `${process.env.REACT_APP_AUTOSUGGESTION_API_URL}suggestions?title=${itemTitle}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getHolidayModeInfo() {
    try {
      return fetch({
        url: "/profiles/dealer/holiday-mode/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async modifyHolidayModeInfo(data) {
    try {
      return fetch({
        url: "/profiles/dealer/holiday-mode/",
        method: "PATCH",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getTradeAssociations() {
    try {
      return await fetch({
        url: "/associations/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async createDealerTradeAssociation(data) {
    try {
      return await fetch({
        url: "/associations/dealer-associations/",
        method: "POST",
        data,
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerTradeAssociations() {
    try {
      return await fetch({
        url: "/associations/dealer-associations/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getOnlineSalesStatistics({ page = 1, pageSize = 5, toDate, fromDate }) {
    const query = new URLSearchParams();

    if (fromDate) {
      query.set("from_date", fromDate);
    }
    if (toDate) {
      query.set("to_date", toDate);
    }
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/profiles/dealer/orders/online-sales-statistic/?${query.toString()}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerItemsCategories(dealerUrl) {
    try {
      return await fetch({
        url: `/storefront/dealers/${dealerUrl}/dealer-categories/`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getDealerSearchActivities() {
    try {
      return await fetch({
        url: "/search/algolia-stats/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async requestDealerSiteBuilderAccess() {
    try {
      return await fetch({
        url: "/profiles/request-dealer-site-builder-access/",
        method: "POST",
        data: {},
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
};

export default profiles;
