import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// redux
import { getAllNotificationsRequest } from "../../redux/notifications/notificationsActions";
import {
  openNotificationDrawer as openNotificationDrawerAction,
} from "../../redux/general/generalActions";

// assets
import BellBadgeOutlineIcon from "../Icons/BellBadgeOutlineIcon";
import BellOutlineIcon from "../Icons/BellOutlineIcon";

const NotificationButton = ({
  authorizedUserProfile,
  openNotificationDrawer,
  getAllNotifications,
  closeMobileDrawer,
  mobileMenuOpen,
}) => {
  const showNotifications = authorizedUserProfile?.role === "DEALER";

  useEffect(() => {
    if (showNotifications) {
      getAllNotifications();
    }
  }, [showNotifications]);

  if (!showNotifications) {
    return null;
  }

  return (
    <div
      className="header--bell-icon"
      onClick={() => {
        if (mobileMenuOpen) {
          closeMobileDrawer();
        }

        openNotificationDrawer();
      }}
    >
      {authorizedUserProfile.unreadNotifications ? (
        <BellBadgeOutlineIcon />
      ) : (
        <BellOutlineIcon />
      )}
    </div>
  );
};

NotificationButton.propTypes = {
  authorizedUserProfile: PropTypes.object.isRequired,
  closeMobileDrawer: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    authorizedUserProfile: state.auth.authorizedUserProfile,
  }),
  (dispatch) => ({
    openNotificationDrawer: () => dispatch(openNotificationDrawerAction()),
    getAllNotifications: () => dispatch(getAllNotificationsRequest()),
  })
)(NotificationButton);
