import { all, call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import { message } from "antd";

import * as types from "./profilesActionsTypes";
import { SHOW_LOADING, HIDE_LOADING } from "../auth/authActionsTypes";
import { GET_ITEM_DATA_REQUEST } from "../items/itemsActionsTypes";
import { GET_SINGLE_DEALER_REQUEST } from "../dealers/dealersActionsTypes";
import profiles from "../../api/profiles";
import dealers from "../../api/dealers";
import general from "../../api/general";
import artisans from "../../api/artisans";
import favorites from "../../api/favorites";
import enquiriesApi from "../../api/enquiries";

import serverSagaWrapper from "../../helpers/serverSagaWrapper";

import {
  getAllEmailPreferencesRequestError,
  getAllEmailPreferencesRequestSuccess,
  createNewEmailPreferenceRequestError,
  createNewEmailPreferenceRequestSuccess,
  deleteEmailPreferenceRequestError,
  deleteEmailPreferenceRequestSuccess,
  patchEmailPreferenceRequestError,
  patchEmailPreferenceRequestSuccess,
  getEmailPreferencesSearchResultRequestError,
  getEmailPreferencesSearchResultRequestSuccess,
  getDealerInvoicesRequestError,
  getDealerInvoicesRequestSuccess,
  searchEmailEnquiriesRequestSuccess,
  searchEmailEnquiriesRequestError,
  searchPhoneEnquiriesRequestSuccess,
  searchPhoneEnquiriesRequestError,
  searchCallbackEnquiriesRequestSuccess,
  searchCallbackEnquiriesRequestError,
  searchDeliveryEnquiriesRequestSuccess,
  searchDeliveryEnquiriesRequestError
} from "./profilesActions";


export function* getAuthorizedUserProfile() {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(profiles.getAuthorizedUserProfile);
    yield put({ type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* patchAuthorizedUserProfile(action) {
  yield put({ type: SHOW_LOADING });
  try {
    const response = yield call(profiles.patchAuthorizedUserProfile, action.payload);
    yield put({ type: types.PATCH_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS, payload: response });

    if (action.callback) {
      yield call(action.callback);
    }

    yield put({ type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.PATCH_AUTHORIZED_USER_PROFILE_REQUEST_ERROR, error: error.data });
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* forgetPassword(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.forgetPassword, action.payload);
    yield put({ type: types.FORGET_PASSWORD_REQUEST_SUCCESS });
  } catch (error) {
    const errorData = error.message ? error.message : error;
    yield put({ type: types.FORGET_PASSWORD_REQUEST_ERROR, payload: errorData });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* resetPassword(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.resetPassword, action.payload);
    yield put({ type: types.RESET_PASSWORD_REQUEST_SUCCESS });
  } catch (error) {
    const errorData = error.message ? error.message : error;
    yield put({ type: types.RESET_PASSWORD_REQUEST_ERROR, payload: errorData });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updatePassword(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.updatePassword, action.payload);
    yield put({ type: types.UPDATE_PASSWORD_SUCCESS });
  } catch (error) {
    const errorData = error.data ? error.data : error;
    yield put({ type: types.UPDATE_PASSWORD_FAILED, payload: errorData });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getFavoriteItemsStatistics(action) {
  yield put({ type: types.TOGGLE_FAV_ITEMS_LOADING, payload: true });
  try {
    const response = yield call(profiles.getFavoriteItems, { ...action.payload, statistic: true });
    yield put({ type: types.GET_FAVORITE_ITEMS_STATISTICS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_FAVORITE_ITEMS_STATISTICS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_FAV_ITEMS_LOADING, payload: false });
  }
}

function* getFavoriteItems(action) {
  yield put({ type: types.TOGGLE_FAV_ITEMS_LOADING, payload: true });
  try {
    const response = yield call(profiles.getFavoriteItems, action.payload);
    yield put({ type: types.GET_FAVORITE_ITEMS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_FAVORITE_ITEMS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_FAV_ITEMS_LOADING, payload: false });
  }
}

function* getFavoriteDealersStatistics(action) {
  yield put({ type: types.TOGGLE_FAV_DEALERS_LOADING, payload: true });
  try {
    const response = yield call(profiles.getFavoriteDealers, { ...action.payload, statistic: true });
    yield put({ type: types.GET_FAVORITE_DEALERS_REQUEST_STATISTICS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_FAVORITE_DEALERS_REQUEST_STATISTICS_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_FAV_DEALERS_LOADING, payload: false });
  }
}

function* getFavoriteDealers(action) {
  yield put({ type: types.TOGGLE_FAV_DEALERS_LOADING, payload: true });
  try {
    const response = yield call(profiles.getFavoriteDealers, action.payload);
    yield put({ type: types.GET_FAVORITE_DEALERS_ITEMS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_FAVORITE_DEALERS_ITEMS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_FAV_DEALERS_LOADING, payload: false });
  }
}

function* addFavoriteItem(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.addFavoriteItem, action.payload.data);
    yield put({ type: types.ADD_FAVORITE_ITEM_SUCCESS });

    if (action.callback) {
      yield call(action.callback)
    }
  } catch (error) {
    yield put({ type: types.ADD_FAVORITE_DEALER_ERROR, payload: error });
  } finally {
    yield put({ type: GET_ITEM_DATA_REQUEST, payload: action.payload });
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* removeFavoriteItem(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(favorites.removeItemFromFavorite, action.payload.data);
    yield put({ type: types.REMOVE_FAVORITE_DEALER_SUCCESS });
  } catch (error) {
    yield put({ type: types.REMOVE_FAVORITE_ITEM_ERROR, payload: error });
  } finally {
    yield put({ type: GET_ITEM_DATA_REQUEST, payload: action.payload });
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* addFavoriteDealer(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.addFavoriteDealer, action.payload.data);
    yield put({ type: types.ADD_FAVORITE_DEALER_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_FAVORITE_DEALER_ERROR, payload: error });
  } finally {
    if (action.payload.itemUrl) {
      yield put({ type: GET_ITEM_DATA_REQUEST, payload: { url: action.payload.itemUrl } });
    }
    if (action.payload.dealerURL) {
      yield put({ type: GET_SINGLE_DEALER_REQUEST, payload: { dealer: action.payload.dealerURL } });
    }
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* removeFavoriteDealer(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.deleteFavoriteDealer, action.payload.data);
    yield put({ type: types.REMOVE_FAVORITE_DEALER_SUCCESS });
  } catch (error) {
    yield put({ type: types.REMOVE_FAVORITE_DEALER_ERROR, payload: error });
  } finally {
    if (action.payload.itemUrl) {
      yield put({ type: GET_ITEM_DATA_REQUEST, payload: { url: action.payload.itemUrl } });
    }
    if (action.payload.dealerURL) {
      yield put({ type: GET_SINGLE_DEALER_REQUEST, payload: { dealer: action.payload.dealerURL } });
    }
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* removeFavoriteDealerFromDealerFavorites(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.deleteFavoriteDealer, { id: action.payload.id });
    yield put({ type: types.REMOVE_FAVORITE_DEALER_SUCCESS });
    yield put({ type: types.GET_FAVORITE_DEALERS_ITEMS_REQUEST, payload: action.payload.params });
  } catch (error) {
    yield put({ type: types.REMOVE_FAVORITE_DEALER_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerDetails() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerDetails);
    const dealerDetails = { ...response };
    delete dealerDetails.specialities;

    yield put({ type: types.GET_CURRENT_DEALER_SUCCESS, payload: dealerDetails });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* editDealerDetails({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.patchDealerDetails, payload);
    yield put({ type: types.EDIT_CURRENT_DEALER_SUCCESS, payload: response });
    yield call(() => callBack());
  } catch (error) {
    yield put({ type: types.EDIT_CURRENT_DEALER_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerImages() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerImages);
    yield put({ type: types.GET_CURRENT_DEALER_IMAGES_SUCCESS, payload: response });
  } catch (err) {
    console.error(err);
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* editDealerImages({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  let image = { name: null };
  try {
    if (payload) {
      image = yield call(general.uploadImage, payload);
    }
    const requestData = { logo: { name: image.name } };
    const response = yield call(profiles.patchDealerImages, payload ? requestData : image.name);
    yield put({ type: types.EDIT_CURRENT_DEALER_IMAGES_SUCCESS, payload: response });
    yield put({ type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.EDIT_CURRENT_DEALER_IMAGES_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* editDealerLogo({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.patchDealerImages, payload);
    yield put({ type: types.EDIT_CURRENT_DEALER_IMAGES_SUCCESS, payload: response });
    yield put({ type: types.GET_AUTHORIZED_USER_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.EDIT_CURRENT_DEALER_IMAGES_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getCurrentDealerAddressesSaga({ payload }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(profiles.getCurrentDealerAddressesApi, payload);
    yield put({ type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* createAddressesForCurrentDealer({ payload }) {
  yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.createAddressesForCurrentDealer, payload);
    message.success("Address has been successfully created!", 3);
    yield put({ type: types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_SUCCESS, payload: response });
    yield put({ type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST, payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    yield put({ type: types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: false });
  }
}

function* patchAddressOfCurrentDealer({ payload }) {
  yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.patchAddressOfCurrentDealer, payload);
    message.success("Address has been successfully updated!", 3);
    yield put({ type: types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS, payload: response });
    yield put({ type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST, payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    yield put({ type: types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: false });
  }
}

function* deleteAddressOfCurrentDealer({ payload }) {
  yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.deleteAddressOfCurrentDealer, payload);
    yield put({ type: types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS, payload: response });
    yield put({ type: types.GET_CURRENT_DEALER_ADDRESSES_REQUEST, payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    yield put({ type: types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROFILE_ADDRESS_PROCESSING, payload: false });
  }
}

function* getDealerSpecialities({ payload }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealerSpecialities, payload);

    yield put({ type: types.GET_DEALER_SPECIALITIES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_SPECIALITIES_ERROR, payload: error })
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* addDealerSpeciality({ payload }) {
  const {
    profiles: {
      dealer: {
        allSpecialities
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(profiles.addDealerSpeciality, payload);
    const updatedSpecialties = allSpecialities.map(item => {
      if (item.id === payload.id) {
        return { ...item, isSelected: true };
      }

      return item;
    });
    yield put({ type: types.ADD_DEALER_SPECIALITY_SUCCESS, payload: updatedSpecialties });
  } catch (error) {
    yield put({ type: types.ADD_DEALER_SPECIALITY_ERROR, payload: error })

  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* getCurrentDealerEmailEnquiriesRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerEmailEnquiriesRequest, payload);
    yield put({ type: types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: false });
  }
}

function* getCurrentDealerDeliveryEnquiriesRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_DELIVERY_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerDeliveryEnquiriesRequest, payload);
    yield put({ type: types.GET_CURRENT_DEALER_DELIVERY_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_DELIVERY_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_DELIVERY_ENQUIRIES_LOADING, payload: false });
  }
}

function* getCurrentDealerPhoneEnquiriesRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerPhoneEnquiriesRequest, payload);
    yield put({ type: types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_LOADING, payload: false });
  }
}

function* getCurrentDealerCallbackEnquiriesRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerCallbackEnquiriesRequest, payload);
    yield put({ type: types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING, payload: false });
  }
}

function* getCurrentDealerEmailEnquiriesForChartRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_FOR_CHART_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerEmailEnquiriesRequest, payload);
    yield put({
      type: types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_ERROR,
      payload: error
    });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_FOR_CHART_LOADING, payload: false });
  }
}

function* getCurrentDealerPhoneEnquiriesForChartRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerPhoneEnquiriesRequest, payload);
    yield put({
      type: types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_ERROR,
      payload: error
    });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING, payload: false });
  }
}

function* getCurrentDealerDeliveryEnquiriesForChartRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerDeliveryEnquiriesRequest, payload);
    yield put({
      type: types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_ERROR,
      payload: error
    });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING, payload: false });
  }
}

function* getCurrentDealerCallbackEnquiriesForChartRequest({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_LOADING, payload: true });
  try {
    const response = yield call(profiles.getCurrentDealerCallbackEnquiriesRequest, payload);
    yield put({
      type: types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_ERROR,
      payload: error
    });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_LOADING, payload: false });
  }
}

function* markEmailEnquiryAsSpam({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.markEmailEnquiryAsSpam, payload.data);
    yield put({ type: types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: false });
    yield put({
      type: types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST,
      payload: {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        page: payload.page,
        pageSize: 5,
      }
    });
  }
}

function* markEmailEnquiryAsRead({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.markEmailEnquiryAsRead, payload.data);
    yield put({ type: types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING, payload: false });
    yield put({
      type: types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST,
      payload: {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        page: payload.page,
        pageSize: 5,
      }
    });
  }
}

function* markCallbackEnquiryAsRead({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING, payload: true });
  try {
    const response = yield call(profiles.markCallbackEnquiryAsRead, payload.data);
    yield put({ type: types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING, payload: false });
    yield put({
      type: types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST,
      payload: {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        page: payload.page,
        pageSize: 5,
      }
    });
  }
}

function* removeDealerSpeciality({ payload }) {
  const {
    profiles: {
      dealer: {
        allSpecialities
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(profiles.removeDealerSpeciality, payload);
    const updatedSpecialties = allSpecialities.map(item => {
      if (item.id === payload.id) {
        return { ...item, isSelected: false };
      }

      return item;
    });

    yield put({ type: types.REMOVE_DEALER_SPECIALITY_SUCCESS, payload: updatedSpecialties });
  } catch (error) {
    yield put({ type: types.REMOVE_DEALER_SPECIALITY_ERROR, payload: error })
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* getCurrentDealerStockImages({ payload }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(profiles.getCurrentDealerStockImages, payload);
    yield put({ type: types.GET_CURRENT_DEALER_STOCK_IMAGES_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.GET_CURRENT_DEALER_STOCK_IMAGES_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* deleteCurrentDealerStockImage({ payload }) {
  const {
    profiles: {
      dealer: {
        stockImages = []
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    yield call(profiles.deleteCurrentDealerStockImage, payload);
    const newItems = stockImages.filter(item => item.id !== payload);

    yield put({ type: types.DELETE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS, payload: newItems });
  } catch (error) {
    yield put({ type: types.DELETE_CURRENT_DEALER_STOCK_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* createCurrentDealerStockImage({ payload }) {
  const {
    profiles: {
      dealer: {
        stockImages = []
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    const image = yield call(general.uploadImage, payload);
    const requestData = { image: { name: image.name } };
    const response = yield call(profiles.createCurrentDealerStockImage, requestData);
    yield put({ type: types.CREATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS, payload: [...stockImages, response] });
  } catch (error) {
    yield put({ type: types.CREATE_CURRENT_DEALER_STOCK_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* updateCurrentDealerStockImageSaga({ payload }) {
  const {
    profiles: {
      dealer: {
        stockImages = []
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(profiles.updateCurrentDealerStockImage, payload);

    yield put({
      type: types.UPDATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS,
      payload: stockImages.map((el) => el.id === response.id ? response : el)
    });
  } catch (error) {
    yield put({ type: types.UPDATE_CURRENT_DEALER_STOCK_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* setAntiqueImageSaga({ payload }) {
  try {
    yield put({ type: types.SHOW_IMAGES_LOADING });

    const results = [];
    if (Array.isArray(payload)) {
      for (const image of payload) {
        const uploadedImage = yield call(general.uploadImage, image);
        results.push(uploadedImage);
      }
    } else {
      const uploadedImage = yield call(general.uploadImage, payload);
      results.push(uploadedImage);
    }
    const {
      profiles: {
        selectedAntiqueItem: {
          images = []
        }
      }
    } = yield select();
    yield put({ type: types.SET_ANTIQUE_IMAGE_SUCCESS, payload: [...images, ...results] });
  } catch (error) {
    yield put({ type: types.SET_ANTIQUE_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_IMAGES_LOADING });
  }
}

function* deleteAntiqueImageSaga({ payload }) {
  const {
    profiles: {
      selectedAntiqueItem: {
        images = []
      }
    }
  } = yield select();

  yield put({ type: types.SHOW_LOADING });
  try {
    const newItems = images.filter(item => item.name !== payload);

    yield put({ type: types.DELETE_ANTIQUE_IMAGE_SUCCESS, payload: newItems });
  } catch (error) {
    yield put({ type: types.DELETE_ANTIQUE_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* searchArtisansSaga({ payload }) {
  try {
    const response = yield call(artisans.getArtisansByName, payload);

    yield put({ type: types.SEARCH_ARTISAN_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.SEARCH_ARTISAN_ERROR, payload: error });
  }
}

function* createItemSaga(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.createItem, action.payload);
    yield call(() => {
      if (action.history.location.state && action.history.location.state.prevPathname) {
        action.history.push(action.history.location.state.prevPathname);
      } else {
        action.history.goBack();
      }
    });
    yield put({ type: types.CREATE_ITEM_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_ITEM_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* editItemSaga(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.editItem, action.payload);
    yield call(() => {
      action.history.goBack();
    });
    yield put({ type: types.EDIT_ITEM_SUCCESS });
    message.success("Your changes have been saved");
  } catch (error) {
    yield put({ type: types.EDIT_ITEM_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteItemSaga(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.deleteItem, action.payload);
    yield put({ type: types.DELETE_ITEM_SUCCESS });
    yield call(() => {
      action.history.goBack();
    });
  } catch (error) {
    yield put({ type: types.DELETE_ITEM_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* rotateImageAntiqueSaga(action) {
  const {
    profiles: {
      selectedAntiqueItem: {
        images = []
      }
    }
  } = yield select();

  yield put({ type: types.ROTATE_ANTIQUE_IMAGE_PROCESSING, payload: true });
  try {
    const response = yield call(general.rotateImage, action.payload);
    const newItems = images.map(item => {
      if (item.name === response.oldName) {
        return { ...item, url: response.url, name: response.name };
      }

      return item;
    });

    yield put({ type: types.ROTATE_ANTIQUE_IMAGE_SUCCESS, payload: newItems });
  } catch (error) {
    yield put({ type: types.ROTATE_ANTIQUE_IMAGE_ERROR, payload: error });
  } finally {
    yield put({ type: types.ROTATE_ANTIQUE_IMAGE_PROCESSING, payload: false });
  }
}

function* getCurrenciesSaga(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(general.getCurrencies, action.payload);
    yield put({ type: types.GET_CURRENCIES_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.GET_CURRENCIES_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* editAuthorizedUserImageRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const image = yield call(general.uploadImage, payload);
    yield put({ type: types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_SUCCESS, payload: image });
  } catch (error) {
    yield put({ type: types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getAllEmailPreferences({ payload }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(profiles.getAllEmailPreferences, payload);
    yield put(getAllEmailPreferencesRequestSuccess(response));
  } catch (error) {
    yield put(getAllEmailPreferencesRequestError(error));
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* createNewEmailPreference({ payload }) {
  yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: true });
  try {
    const response = yield call(profiles.createNewEmailPreference, payload);
    yield put(createNewEmailPreferenceRequestSuccess(response));
    message.success("New email preference was successfully added to the list!", 3);
    yield put({ type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST, payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    yield put(createNewEmailPreferenceRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: false });
  }
}

function* deleteEmailPreference({ payload, params = {} }) {
  yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: true });
  try {
    const response = yield call(profiles.deleteEmailPreference, payload);
    yield put(deleteEmailPreferenceRequestSuccess(response));
    message.success("Email preference was successfully deleted!", 3);
    yield put({ type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST, payload: { pageSize: 10, ...params } });
  } catch (error) {
    yield put(deleteEmailPreferenceRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: false });
  }
}

function* patchEmailPreference({ payload }) {
  yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.patchEmailPreference, payload);
    yield put(patchEmailPreferenceRequestSuccess(response));
    yield put({ type: types.GET_ALL_EMAIL_PREFERENCES_REQUEST, payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    yield put(patchEmailPreferenceRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_PROCESSING, payload: false });
  }
}

function* getEmailPreferenceSearchResult({ payload }) {
  yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getEmailPreferencesSearchResult, payload);
    yield put(getEmailPreferencesSearchResultRequestSuccess(response));
  } catch (error) {
    yield put(getEmailPreferencesSearchResultRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_EMAIL_PREFERENCES_LOADING, payload: false });
  }
}

function* getDealerInvoices({ payload }) {
  yield put({ type: types.TOGGLE_DEALER_INVOICES_LOADING, payload: true });
  try {
    const response = yield call(profiles.getAuthorizedDealerInvoices, payload);
    yield put(getDealerInvoicesRequestSuccess(response));
  } catch (error) {
    yield put(getDealerInvoicesRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_DEALER_INVOICES_LOADING, payload: false });
  }
}

function* searchEnquiries({ payload }) {
  try {
    const emailResponse = yield call(enquiriesApi.searchEmailEnquiries, payload);
    yield put(searchEmailEnquiriesRequestSuccess(emailResponse));
  } catch (error) {
    yield put(searchEmailEnquiriesRequestError(error));
  }
}

function* searchPhoneEnquiries({ payload }) {
  try {
    const phoneResponse = yield call(enquiriesApi.searchPhoneEnquiries, payload);
    yield put(searchPhoneEnquiriesRequestSuccess(phoneResponse));
  } catch (error) {
    yield put(searchPhoneEnquiriesRequestError(error));
  }
}

function* searchCallbackEnquiries({ payload }) {
  try {
    const callbackResponse = yield call(enquiriesApi.searchCallbackEnquiries, payload);
    yield put(searchCallbackEnquiriesRequestSuccess(callbackResponse));
  } catch (error) {
    yield put(searchCallbackEnquiriesRequestError(error));
  }
}

function* searchDeliveryEnquiries({ payload }) {
  try {
    const deliveryResponse = yield call(enquiriesApi.searchDeliveryEnquiries, payload);
    yield put(searchDeliveryEnquiriesRequestSuccess(deliveryResponse));
  } catch (error) {
    yield put(searchDeliveryEnquiriesRequestError(error));
  }
}

function* createDefaultText({ payload }) {
  try {
    yield call(profiles.createDefaultText, payload);
    yield call(() => {
      payload.history.push("/profile/default-settings?tab=default-text");
    })
  } catch (err) {
    yield put({ type: types.CREATE_DEFAULT_TEXT_REQUEST_ERROR, payload: err })
  }
}

function* getAllDefaultTexts() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getAllDefaultTexts);
    yield put({ type: types.GET_ALL_DEFAULT_TEXTS_REQUEST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_ALL_DEFAULT_TEXTS_REQUEST_ERROR, payload: err })
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteDefaultText({ payload }) {
  try {
    yield call(profiles.deleteDefaultText, payload);
    yield put({ type: types.GET_ALL_DEFAULT_TEXTS_REQUEST });
  } catch (err) {
    yield put({ type: types.DELETE_DEFAULT_TEXT_REQUEST_ERROR, payload: err });
  }
}

function* getSingleDefaultText({ payload }) {
  try {
    const response = yield call(profiles.getSingleDefaultText, payload);
    yield put({ type: types.GET_SINGLE_DEALER_TEXT_REQUEST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_SINGLE_DEALER_TEXT_REQUEST_ERROR, payload: err });
  }
}

function* patchDefaultText({ payload }) {
  try {
    yield call(profiles.patchDefaultText, payload);
    yield call(() => {
      payload.history.push("/profile/default-settings?tab=default-text");
    });
  } catch (err) {
    yield put({ type: types.PATCH_DEFAULT_TEXT_REQUEST_ERROR, payload: err });
  }
}

function* createDealerWebsite({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.createDealerWebsite, payload);
    yield put({ type: types.CREATE_DEALER_WEBSITE_REQUEST_SUCCESS, payload: response });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_WEBSITE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateDealerWebsite({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.updateDealerWebsite, payload);
    yield put({ type: types.UPDATE_DEALER_WEBSITE_REQUEST_SUCCESS });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_WEBSITE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerWebsite({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsite, payload);
    yield put({
      type: types.GET_DEALER_WEBSITE_REQUEST_SUCCESS,
      payload: response.results ? response.results[0] : {}
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createDealerWebsiteInfo({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.createDealerWebsiteInfo, payload);
    yield put({ type: types.CREATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_WEBSITE_INFO_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateDealerWebsiteInfo({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.updateDealerWebsiteInfo, payload);
    yield put({ type: types.UPDATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_WEBSITE_INFO_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerWebsiteInfo({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsiteInfo, payload);
    yield put({
      type: types.GET_DEALER_WEBSITE_INFO_REQUEST_SUCCESS,
      payload: response.results ? response.results[0] : {}
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_INFO_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* sendDealerWebsiteDomainRequest({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.sendDealerWebsiteDomainRequest, payload);
    yield put({ type: types.SEND_DEALER_WEBSITE_DOMAIN_REQUEST_SUCCESS });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.SEND_DEALER_WEBSITE_DOMAIN_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createDealerWebsitePage({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.createDealerWebsitePage, payload.data, payload.pageType);
    yield put({ type: types.CREATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateDealerWebsitePage({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.updateDealerWebsitePage, payload, "pages", payload.isSales);

    if (payload.pageType === "STOCK") {
      yield call(profiles.updateDealerWebsite, {
        id: payload.site,
        stockPageLayout: payload.stockPageLayout
      });
    }

    yield put({ type: types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS, payload: response });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateDealerWebsiteBlog({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.updateDealerWebsitePage, payload, "dealers/blog-pages");
    yield put({ type: types.UPDATE_DEALER_WEBSITE_BLOG_REQUEST_SUCCESS, payload: response });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_WEBSITE_BLOG_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerWebsitePage({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsitePage, payload.data, payload.pageType, payload.isSales);
    yield put({
      type: types.GET_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_PAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteDealerWebsitePage({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.deleteDealerWebsitePage, payload.data, payload.pageType);
    yield put({
      type: types.DELETE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS,
      payload: response
    });
    yield put({
      type: payload.pageType === "pages"
        ? types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST
        : types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST,
      payload: { isSales: payload.pageType === "pages" }
    });
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_WEBSITE_PAGE_REQUEST_ERROR, payload: error });
  }
}

function* getDealerWebsitePagesList({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsitePagesList, payload);
    yield put({
      type: types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_SUCCESS,
      payload: response.results
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


function* getDealerWebsiteBlogsList() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsiteBlogsList);
    yield put({
      type: types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_SUCCESS,
      payload: response.results
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* publishDealerWebsite({ payload, callBack }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.publishDealerWebsite, payload);
    yield put({ type: types.PUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS, payload: "PENDING" });
    yield call(callBack);
  } catch (error) {
    yield put({ type: types.PUBLISH_DEALER_WEBSITE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* publishDeclinedDealerWebsite({ payload }) {
  try {
    yield call(profiles.publishDeclinedDealerWebsite, payload);
    yield put({ type: types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_SUCCESS, payload: "PENDING" });
  } catch (error) {
    yield put({ type: types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_ERROR, payload: error.detail });
  }
}

function* unpublishDealerWebsite({ payload }) {
  try {
    yield call(profiles.unpublishDealerWebsite, payload);
    yield put({ type: types.UNPUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS, payload: null });
  } catch (error) {
    yield put({ type: types.UNPUBLISH_DEALER_WEBSITE_REQUEST_ERROR, payload: error.detail });
  }
}

function* getMarketplaces({ payload }) {
  yield put({ type: types.SHOW_MARKETPLACES_LOADING });

  try {
    const response = yield call(profiles.getMarketplaces, payload);
    yield put({ type: types.GET_MARKETPLACES_REQUEST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_MARKETPLACES_REQUEST_ERROR, payload: err.detail });
  } finally {
    yield put({ type: types.HIDE_MARKETPLACES_LOADING });
  }
}

function* connectMarketplace({ payload }) {
  try {
    yield call(profiles.connectMarketplace, payload)
  } catch (err) {
    yield put({ type: types.CONNECT_MARKETPLACE_REQUEST_ERROR, payload: err.detail });
  }
}

function* getDealerWebsiteSubscriptions({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.getDealerWebsiteSubscriptions, payload);
    yield put({
      type: types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({ type: types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteDealerWebsiteSubscription({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(profiles.deleteDealerWebsiteSubscription, payload);
    yield put({ type: types.DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS, payload: { id: payload } });
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateDealerWebsiteSubscription({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(profiles.updateDealerWebsiteSubscription, payload);
    yield put({
      type: types.UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* exportDealerWebsiteSubscriptions({ payload }) {
  try {
    const response = yield call(profiles.exportDealerWebsiteSubscriptions, payload);

    yield put({
      type: types.EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_SUCCESS,
      payload: response
    });

    const downloadLink = document.createElement("a");
    const blob = new Blob(["\ufeff", response]);
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "subscriptions.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    yield put({ type: types.EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSuggestedCategories({ payload, callback }) {
  try {
    const response = yield call(profiles.getSuggestedCategory, payload);
    yield put({ type: types.GET_SUGGESTED_CATEGORIES_REQUEST_SUCCESS, payload: response });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_SUGGESTED_CATEGORIES_REQUEST_ERROR, payload: error });
  }
}

function* getHolidayModeDetails({ payload }) {
  try {
    const response = yield call(profiles.getHolidayModeInfo, payload);
    yield put({ type: types.GET_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_HOLIDAY_MODE_DETAILS_REQUEST_ERROR, payload: error });
  }
}

function* modifyHolidayModeDetails({ payload }) {
  try {
    const response = yield call(profiles.modifyHolidayModeInfo, payload);
    yield put({ type: types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_ERROR, payload: error });
  }
}

function* getTradeAssociations({ payload }) {
  try {
    const response = yield call(profiles.getTradeAssociations, payload);
    yield put({ type: types.GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.GET_TRADE_ASSOCIATIONS_REQUEST_ERROR, payload: error });
  }
}

function* createDealerTradeAssociations({ payload, callback }) {
  try {
    yield call(profiles.createDealerTradeAssociation, payload);
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR, payload: error });
  }
}

function* getDealerTradeAssociations({ payload }) {
  try {
    const response = yield call(profiles.getDealerTradeAssociations, payload);
    yield put({ type: types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR, payload: error });
  }
}

function* getOnlineSalesStatistics({ payload }) {
  try {
    const response = yield call(profiles.getOnlineSalesStatistics, payload);
    yield put({ type: types.GET_ONLINE_SALES_STATISTICS_REQUEST_SUCCESS, payload: response.count });
  } catch (error) {
    yield put({ type: types.GET_ONLINE_SALES_STATISTICS_REQUEST_ERROR, payload: error });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_AUTHORIZED_USER_PROFILE_REQUEST, serverSagaWrapper(getAuthorizedUserProfile)),
    yield takeLatest(types.PATCH_AUTHORIZED_USER_PROFILE_REQUEST, serverSagaWrapper(patchAuthorizedUserProfile)),
    yield takeLatest(types.FORGET_PASSWORD_REQUEST, serverSagaWrapper(forgetPassword)),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, serverSagaWrapper(resetPassword)),
    yield takeLatest(types.UPDATE_PASSWORD_REQUEST, serverSagaWrapper(updatePassword)),
    yield takeLatest(types.GET_FAVORITE_ITEMS_STATISTICS_REQUEST, serverSagaWrapper(getFavoriteItemsStatistics)),
    yield takeLatest(types.GET_FAVORITE_DEALERS_STATISTICS_REQUEST, serverSagaWrapper(getFavoriteDealersStatistics)),
    yield takeLatest(types.ADD_FAVORITE_ITEM_REQUEST, serverSagaWrapper(addFavoriteItem)),
    yield takeLatest(types.REMOVE_FAVORITE_ITEM_REQUEST, serverSagaWrapper(removeFavoriteItem)),
    yield takeLatest(types.ADD_FAVORITE_DEALER_REQUEST, serverSagaWrapper(addFavoriteDealer)),
    yield takeLatest(types.REMOVE_FAVORITE_DEALER_REQUEST, serverSagaWrapper(removeFavoriteDealer)),
    yield takeLatest(
      types.REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_REQUEST,
      removeFavoriteDealerFromDealerFavorites
    ),
    yield takeLatest(types.GET_CURRENT_DEALER_REQUEST, serverSagaWrapper(getDealerDetails)),
    yield takeLatest(types.EDIT_CURRENT_DEALER_REQUEST, serverSagaWrapper(editDealerDetails)),
    yield takeLatest(types.GET_CURRENT_DEALER_IMAGES_REQUEST, serverSagaWrapper(getDealerImages)),
    yield takeLatest(types.EDIT_CURRENT_DEALER_IMAGES_REQUEST, serverSagaWrapper(editDealerImages)),
    yield takeLatest(types.EDIT_CURRENT_DEALER_LOGO_REQUEST, serverSagaWrapper(editDealerLogo)),
    yield takeLatest(types.GET_CURRENT_DEALER_ADDRESSES_REQUEST, serverSagaWrapper(getCurrentDealerAddressesSaga)),
    yield takeLatest(types.CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST, serverSagaWrapper(createAddressesForCurrentDealer)),
    yield takeLatest(types.PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST, serverSagaWrapper(patchAddressOfCurrentDealer)),
    yield takeLatest(types.GET_CURRENT_DEALER_STOCK_IMAGES_REQUEST, serverSagaWrapper(getCurrentDealerStockImages)),
    yield takeLatest(types.DELETE_CURRENT_DEALER_STOCK_IMAGE_REQUEST, serverSagaWrapper(deleteCurrentDealerStockImage)),
    yield takeLatest(types.CREATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST, serverSagaWrapper(createCurrentDealerStockImage)),
    yield takeLatest(types.UPDATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST, serverSagaWrapper(updateCurrentDealerStockImageSaga)),
    yield takeLatest(types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_REQUEST, serverSagaWrapper(editAuthorizedUserImageRequest)),
    yield takeLatest(types.DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST, serverSagaWrapper(deleteAddressOfCurrentDealer)),
    yield takeLatest(
      types.GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST,
      serverSagaWrapper(getCurrentDealerEmailEnquiriesRequest)
    ),
    yield takeLatest(
      types.GET_CURRENT_DEALER_DELIVERY_ENQUIRIES_REQUEST,
      serverSagaWrapper(getCurrentDealerDeliveryEnquiriesRequest)
    ),
    yield takeLatest(
      types.GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST,
      serverSagaWrapper(getCurrentDealerPhoneEnquiriesRequest)
    ),
    yield takeLatest(
      types.GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST,
      serverSagaWrapper(getCurrentDealerCallbackEnquiriesRequest)
    ),
    yield takeLatest(
      types.GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST,
      serverSagaWrapper(getCurrentDealerEmailEnquiriesForChartRequest)
    ),
    yield takeLatest(
      types.GET_CURRENT_DEALER_DELIVERY_FOR_CHART_REQUEST,
      serverSagaWrapper(getCurrentDealerDeliveryEnquiriesForChartRequest)
    ),
    yield takeLatest(
      types.GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST,
      serverSagaWrapper(getCurrentDealerPhoneEnquiriesForChartRequest)
    ),
    yield takeLatest(
      types.GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST,
      serverSagaWrapper(getCurrentDealerCallbackEnquiriesForChartRequest)
    ),
    yield takeLatest(types.ADD_DEALER_SPECIALITY_REQUEST, serverSagaWrapper(addDealerSpeciality)),
    yield takeLatest(types.REMOVE_DEALER_SPECIALITY_REQUEST, serverSagaWrapper(removeDealerSpeciality)),
    yield takeLatest(types.GET_DEALER_SPECIALITIES_REQUEST, serverSagaWrapper(getDealerSpecialities)),
    yield takeEvery(types.SET_ANTIQUE_IMAGE_REQUEST, serverSagaWrapper(setAntiqueImageSaga)),
    yield takeLatest(types.DELETE_ANTIQUE_IMAGE_REQUEST, serverSagaWrapper(deleteAntiqueImageSaga)),
    yield takeLatest(types.MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST, serverSagaWrapper(markEmailEnquiryAsSpam)),
    yield takeLatest(types.MARK_EMAIL_ENQUIRY_AS_READ_REQUEST, serverSagaWrapper(markEmailEnquiryAsRead)),
    yield takeLatest(types.MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST, serverSagaWrapper(markCallbackEnquiryAsRead)),
    yield takeLatest(types.SEARCH_ARTISAN_REQUEST, serverSagaWrapper(searchArtisansSaga)),
    yield takeLatest(types.CREATE_ITEM_REQUEST, serverSagaWrapper(createItemSaga)),
    yield takeLatest(types.EDIT_ITEM_REQUEST, serverSagaWrapper(editItemSaga)),
    yield takeLatest(types.DELETE_ITEM_REQUEST, serverSagaWrapper(deleteItemSaga)),
    yield takeLatest(types.ROTATE_ANTIQUE_IMAGE_REQUEST, serverSagaWrapper(rotateImageAntiqueSaga)),
    yield takeLatest(types.GET_CURRENCIES_REQUEST, serverSagaWrapper(getCurrenciesSaga)),
    yield takeLatest(
      types.UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_REQUEST, serverSagaWrapper(editAuthorizedUserImageRequest)
    ),
    yield takeLatest(types.GET_ALL_EMAIL_PREFERENCES_REQUEST, serverSagaWrapper(getAllEmailPreferences)),
    yield takeLatest(types.CREATE_NEW_EMAIL_PREFERENCE_REQUEST, serverSagaWrapper(createNewEmailPreference)),
    yield takeLatest(types.DELETE_EMAIL_PREFERENCE_REQUEST, serverSagaWrapper(deleteEmailPreference)),
    yield takeLatest(types.PATCH_EMAIL_PREFERENCE_REQUEST, serverSagaWrapper(patchEmailPreference)),
    yield takeLatest(
      types.GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST,
      serverSagaWrapper(getEmailPreferenceSearchResult)
    ),
    yield takeLatest(types.GET_DEALER_INVOICES_REQUEST, serverSagaWrapper(getDealerInvoices)),
    yield takeLatest(types.SEARCH_EMAIL_ENQUIRIES_REQUEST, serverSagaWrapper(searchEnquiries)),
    yield takeLatest(types.SEARCH_PHONE_ENQUIRIES_REQUEST, serverSagaWrapper(searchPhoneEnquiries)),
    yield takeLatest(types.SEARCH_CALLBACK_ENQUIRIES_REQUEST, serverSagaWrapper(searchCallbackEnquiries)),
    yield takeLatest(types.SEARCH_DELIVERY_ENQUIRIES_REQUEST, serverSagaWrapper(searchDeliveryEnquiries)),
    yield takeLatest(types.CREATE_DEFAULT_TEXT_REQUEST, createDefaultText),
    yield takeLatest(types.GET_ALL_DEFAULT_TEXTS_REQUEST, getAllDefaultTexts),
    yield takeLatest(types.DELETE_DEFAULT_TEXT_REQUEST, deleteDefaultText),
    yield takeLatest(types.GET_SINGLE_DEALER_TEXT_REQUEST, serverSagaWrapper(getSingleDefaultText)),
    yield takeLatest(types.PATCH_DEFAULT_TEXT_REQUEST, patchDefaultText),
    yield takeLatest(types.GET_FAVORITE_ITEMS_REQUEST, serverSagaWrapper(getFavoriteItems)),
    yield takeLatest(types.GET_FAVORITE_DEALERS_ITEMS_REQUEST, serverSagaWrapper(getFavoriteDealers)),
    yield takeLatest(types.CREATE_DEALER_WEBSITE_REQUEST, createDealerWebsite),
    yield takeLatest(types.UPDATE_DEALER_WEBSITE_REQUEST, updateDealerWebsite),
    yield takeLatest(types.GET_DEALER_WEBSITE_REQUEST, serverSagaWrapper(getDealerWebsite)),
    yield takeLatest(types.CREATE_DEALER_WEBSITE_INFO_REQUEST, createDealerWebsiteInfo),
    yield takeLatest(types.UPDATE_DEALER_WEBSITE_INFO_REQUEST, updateDealerWebsiteInfo),
    yield takeLatest(types.GET_DEALER_WEBSITE_INFO_REQUEST, serverSagaWrapper(getDealerWebsiteInfo)),
    yield takeLatest(types.SEND_DEALER_WEBSITE_DOMAIN_REQUEST, sendDealerWebsiteDomainRequest),
    yield takeLatest(types.CREATE_DEALER_WEBSITE_PAGE_REQUEST, createDealerWebsitePage),
    yield takeLatest(types.UPDATE_DEALER_WEBSITE_PAGE_REQUEST, updateDealerWebsitePage),
    yield takeLatest(types.UPDATE_DEALER_WEBSITE_BLOG_REQUEST, updateDealerWebsiteBlog),
    yield takeLatest(types.GET_DEALER_WEBSITE_PAGE_REQUEST, serverSagaWrapper(getDealerWebsitePage)),
    yield takeLatest(types.GET_DEALER_WEBSITE_PAGES_LIST_REQUEST, serverSagaWrapper(getDealerWebsitePagesList)),
    yield takeLatest(types.GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST, serverSagaWrapper(getDealerWebsiteBlogsList)),
    yield takeLatest(types.DELETE_DEALER_WEBSITE_PAGE_REQUEST, deleteDealerWebsitePage),
    yield takeLatest(types.PUBLISH_DEALER_WEBSITE_REQUEST, publishDealerWebsite),
    yield takeLatest(types.PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST, publishDeclinedDealerWebsite),
    yield takeLatest(types.UNPUBLISH_DEALER_WEBSITE_REQUEST, unpublishDealerWebsite),
    yield takeLatest(types.GET_MARKETPLACES_REQUEST, getMarketplaces),
    yield takeLatest(types.CONNECT_MARKETPLACE_REQUEST, connectMarketplace),
    yield takeLatest(types.GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST, serverSagaWrapper(getDealerWebsiteSubscriptions)),
    yield takeLatest(types.DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST, deleteDealerWebsiteSubscription),
    yield takeLatest(types.UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST, updateDealerWebsiteSubscription),
    yield takeLatest(types.EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST, exportDealerWebsiteSubscriptions),
    yield takeLatest(types.GET_SUGGESTED_CATEGORIES_REQUEST, getSuggestedCategories),
    yield takeLatest(types.GET_HOLIDAY_MODE_DETAILS_REQUEST, getHolidayModeDetails),
    yield takeLatest(types.MODIFY_HOLIDAY_MODE_DETAILS_REQUEST, modifyHolidayModeDetails),
    yield takeLatest(types.GET_TRADE_ASSOCIATIONS_REQUEST, getTradeAssociations),
    yield takeLatest(types.CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST, createDealerTradeAssociations),
    yield takeLatest(types.GET_DEALER_TRADE_ASSOCIATIONS_REQUEST, getDealerTradeAssociations),
    yield takeLatest(types.GET_ONLINE_SALES_STATISTICS_REQUEST, getOnlineSalesStatistics)
  ])
}
