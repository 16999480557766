export * from "./country-list";
export * from "./country-reach-data";
export * from "./england";
export * from "./northern-ireland";
export * from "./scotland";
export * from "./wales";

import { ENGLAND_OPTIONS } from "./england";
import { NORTHERN_IRELAND_OPTIONS } from "./northern-ireland";
import { SCOTLAND_OPTIONS } from "./scotland";
import { WALES_OPTIONS } from "./wales";

export const UK_COUNTY_OPTIONS = [...ENGLAND_OPTIONS, ...NORTHERN_IRELAND_OPTIONS, ...SCOTLAND_OPTIONS, ...WALES_OPTIONS];

export const UK_COUNTY_SELECT_OPTIONS = [
  {
    label: "England",
    options: ENGLAND_OPTIONS.map(({ county }) => ({ value: county, label: county })),
  },
  {
    label: "Northern Ireland",
    options: NORTHERN_IRELAND_OPTIONS.map(({ county }) => ({ value: county, label: county })),
  },
  {
    label: "Scotland",
    options: SCOTLAND_OPTIONS.map(({ county }) => ({ value: county, label: county })),
  },
  {
    label: "Wales",
    options: WALES_OPTIONS.map(({ county }) => ({ value: county, label: county })),
  },
];
