import fetch from "./customFetch";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

// helpers
import { objectToQueryString } from "../helpers";


const auth = {
  async login(data) {
    const cookies = new Cookies();

    try {
      const result = await fetch({
        url: "/auth/",
        method: "POST",
        data
      });

      const decodedAccessToken = jwtDecode(result.access);
      const accessTokenExpTime = decodedAccessToken && new Date(decodedAccessToken.exp * 1000);
      cookies.set("accessToken", result.access, { expires: accessTokenExpTime, path: "/" });
      cookies.set("refreshToken", result.refresh, { path: "/" });
      sessionStorage.setItem("advertiseWebsiteBuilder", String(result.advertiseWebsiteBuilder));

      return result;
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async registerVisitor(data) {
    try {
      const result = await fetch({
        url: "/auth/register/",
        method: "POST",
        data
      });

      return result.data
    }
    catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async registerTrader(data) {
    try {
      const result = await fetch({
        url: "/auth/register/trader/",
        method: "POST",
        data
      });

      return result.data
    }
    catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async activateAccount(data) {
    const cookies = new Cookies();

    try {
      const resultActivated = await fetch({
        url: "/auth/activate/",
        method: "POST",
        data
      });
      const result = {
        access: resultActivated?.accessToken,
        refresh: resultActivated?.refreshToken,
      };

      const decodedAccessToken = jwtDecode(result.access);
      const accessTokenExpTime = decodedAccessToken && new Date(decodedAccessToken.exp * 1000);
      cookies.set("accessToken", result.access, { expires: accessTokenExpTime, path: "/" });
      cookies.set("refreshToken", result.refresh, { path: "/" });

      return result
    }
    catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  async registerDealer(data) {
    try {
      const result = await fetch({
        url: "/auth/register/dealer/",
        method: "POST",
        data
      });

      return result.data
    }
    catch (err) {
      if (err.response && err.response.data) {
        throw err.response.data;
      }
      throw err;
    }
  },

  logout() {
    const cookies = new Cookies();

    cookies.remove("accessToken", { path: "/", domain: process.env.REACT_APP_DOMAIN_NAME }); // for cookie set from CRM
    cookies.remove("accessToken", { path: "/" }); // for cookie set from storefront
    cookies.remove("refreshToken", { path: "/" });
    cookies.remove("isDeliveryNotification", { path: "/" });
    cookies.remove("showDealersNewsPopup", { path: "/" });
    sessionStorage.removeItem("advertiseWebsiteBuilder");

    return true;
  },

  async setSmsNotificationChecked(data) {
    try {
      return await fetch({
        url: "/profiles/",
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async instagramLogin(data) {
    try {
      return await fetch({
        url: "/instagram/token/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async storeFacebookCatalog(params = {}) {
    try {
      const query = objectToQueryString(params);

      return await fetch({
        url: `/facebook/set-catalog-id/?${query}`,
        method: "GET",
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getSocialAuthKeys() {
    try {
      return await fetch({
        url: "/auth/social-logins/",
        method: "GET",
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async getGoogleCaptchaKey() {
    try {
      return await fetch({
        url: "/auth/captcha/",
        method: "GET",
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async checkTraderBusinessName(data) {
    try {
      return await fetch({
        url: "/auth/register/trader/check-business-name/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  }
};

export default auth;
