import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// assets
import HeartIcon from "../Icons/Heart";

const FavoritesIcon = ({
  isAuthenticated,
  closeMobileDrawer,
}) => {
  return (
    <Link
      to={isAuthenticated ? "/profile/favorites/items" : "/favorites/items"}
      className="header--heart-icon"
      aria-label="Redirect to favorite items"
    >
      <HeartIcon onClick={closeMobileDrawer} />
    </Link>
  );
};

FavoritesIcon.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  closeMobileDrawer: PropTypes.func.isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
}))(FavoritesIcon);
