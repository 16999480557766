export const GET_AUTHORIZED_USER_PROFILE_REQUEST = "GET_AUTHORIZED_USER_PROFILE_REQUEST";
export const GET_AUTHORIZED_USER_PROFILE_REQUEST_ERROR = "GET_AUTHORIZED_USER_PROFILE_REQUEST_ERROR";
export const GET_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS = "GET_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS";

export const PATCH_AUTHORIZED_USER_PROFILE_REQUEST = "PATCH_AUTHORIZED_USER_PROFILE_REQUEST";
export const PATCH_AUTHORIZED_USER_PROFILE_REQUEST_ERROR = "PATCH_AUTHORIZED_USER_PROFILE_REQUEST_ERROR";
export const PATCH_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS = "PATCH_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_REQUEST_ERROR = "FORGET_PASSWORD_REQUEST_ERROR";
export const FORGET_PASSWORD_REQUEST_SUCCESS = "FORGET_PASSWORD_REQUEST_SUCCESS";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const GET_FAVORITE_ITEMS_STATISTICS_REQUEST = "GET_FAVORITE_ITEMS_STATISTICS_REQUEST";
export const GET_FAVORITE_ITEMS_STATISTICS_REQUEST_ERROR = "GET_FAVORITE_ITEMS_STATISTICS_REQUEST_ERROR";
export const GET_FAVORITE_ITEMS_STATISTICS_REQUEST_SUCCESS = "GET_FAVORITE_ITEMS_STATISTICS_REQUEST_SUCCESS";

export const GET_FAVORITE_ITEMS_REQUEST = "GET_FAVORITE_ITEMS_REQUEST";
export const GET_FAVORITE_ITEMS_REQUEST_SUCCESS = "GET_FAVORITE_ITEMS_REQUEST_SUCCESS";
export const GET_FAVORITE_ITEMS_REQUEST_ERROR = "GET_FAVORITE_ITEMS_REQUEST_ERROR";

export const GET_FAVORITE_DEALERS_STATISTICS_REQUEST = "GET_FAVORITE_DEALERS_STATISTICS_REQUEST";
export const GET_FAVORITE_DEALERS_REQUEST_STATISTICS_ERROR = "GET_FAVORITE_DEALERS_REQUEST_STATISTICS_ERROR";
export const GET_FAVORITE_DEALERS_REQUEST_STATISTICS_SUCCESS = "GET_FAVORITE_DEALERS_REQUEST_SUCCESS";

export const GET_FAVORITE_DEALERS_ITEMS_REQUEST = "GET_FAVORITE_DEALERS_ITEMS_REQUEST";
export const GET_FAVORITE_DEALERS_ITEMS_REQUEST_SUCCESS = "GET_FAVORITE_DEALERS_ITEMS_REQUEST_SUCCESS";
export const GET_FAVORITE_DEALERS_ITEMS_REQUEST_ERROR = "GET_FAVORITE_DEALERS_ITEMS_REQUEST_ERROR";

export const ADD_FAVORITE_ITEM_REQUEST = "ADD_FAVORITE_ITEM_REQUEST";
export const ADD_FAVORITE_ITEM_ERROR = "ADD_FAVORITE_ITEM_ERROR";
export const ADD_FAVORITE_ITEM_SUCCESS = "ADD_FAVORITE_ITEM_SUCCESS";

export const REMOVE_FAVORITE_ITEM_REQUEST = "REMOVE_FAVORITE_ITEM_REQUEST";
export const REMOVE_FAVORITE_ITEM_ERROR = "REMOVE_FAVORITE_ITEM_ERROR";
export const REMOVE_FAVORITE_ITEM_SUCCESS = "REMOVE_FAVORITE_ITEM_SUCCESS";

export const REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_REQUEST =
  "REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_REQUEST";
export const REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_ERROR =
  "REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_ERROR";
export const REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_SUCCESS =
  "REMOVE_FAVORITE_DEALER_FROM_DEALER_FAVORITES_SUCCESS";

export const ADD_FAVORITE_DEALER_REQUEST = "ADD_FAVORITE_DEALER_REQUEST";
export const ADD_FAVORITE_DEALER_ERROR = "ADD_FAVORITE_DEALER_ERROR";
export const ADD_FAVORITE_DEALER_SUCCESS = "ADD_FAVORITE_DEALER_SUCCESS";

export const REMOVE_FAVORITE_DEALER_REQUEST = "REMOVE_FAVORITE_DEALER_REQUEST";
export const REMOVE_FAVORITE_DEALER_ERROR = "REMOVE_FAVORITE_DEALER_ERROR";
export const REMOVE_FAVORITE_DEALER_SUCCESS = "REMOVE_FAVORITE_DEALER_SUCCESS";

export const GET_CURRENT_DEALER_REQUEST = "GET_CURRENT_DEALER_REQUEST";
export const GET_CURRENT_DEALER_ERROR = "GET_CURRENT_DEALER_ERROR";
export const GET_CURRENT_DEALER_SUCCESS = "GET_CURRENT_DEALER_SUCCESS";

export const GET_CURRENT_DEALER_IMAGES_REQUEST = "GET_CURRENT_DEALER_IMAGES_REQUEST";
export const GET_CURRENT_DEALER_IMAGES_ERROR = "GET_CURRENT_DEALER_IMAGES_ERROR";
export const GET_CURRENT_DEALER_IMAGES_SUCCESS = "GET_CURRENT_DEALER_IMAGES_SUCCESS";

export const EDIT_CURRENT_DEALER_IMAGES_REQUEST = "EDIT_CURRENT_DEALER_IMAGES_REQUEST";
export const EDIT_CURRENT_DEALER_IMAGES_ERROR = "EDIT_CURRENT_DEALER_IMAGES_ERROR";
export const EDIT_CURRENT_DEALER_IMAGES_SUCCESS = "EDIT_CURRENT_DEALER_IMAGES_SUCCESS";

export const EDIT_CURRENT_DEALER_LOGO_REQUEST = "EDIT_CURRENT_DEALER_LOGO_REQUEST";

export const EDIT_CURRENT_DEALER_REQUEST = "EDIT_CURRENT_DEALER_REQUEST";
export const EDIT_CURRENT_DEALER_ERROR = "EDIT_CURRENT_DEALER_ERROR";
export const EDIT_CURRENT_DEALER_SUCCESS = "EDIT_CURRENT_DEALER_SUCCESS";

export const GET_CURRENT_DEALER_ADDRESSES_REQUEST = "GET_CURRENT_DEALER_ADDRESSES_REQUEST";
export const GET_CURRENT_DEALER_ADDRESSES_REQUEST_ERROR = "GET_CURRENT_DEALER_ADDRESSES_REQUEST_ERROR";
export const GET_CURRENT_DEALER_ADDRESSES_REQUEST_SUCCESS = "GET_CURRENT_DEALER_ADDRESSES_REQUEST_SUCCESS";

export const CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST = "CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST";
export const CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_ERROR = "CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_ERROR";
export const CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_SUCCESS = "CREATE_ADDRESS_FOR_CURRENT_DEALER_REQUEST_SUCCESS";

export const GET_CURRENT_DEALER_STOCK_IMAGES_REQUEST = "GET_CURRENT_DEALER_STOCK_IMAGES_REQUEST";
export const GET_CURRENT_DEALER_STOCK_IMAGES_ERROR = "GET_CURRENT_DEALER_STOCK_IMAGES_ERROR";
export const GET_CURRENT_DEALER_STOCK_IMAGES_SUCCESS = "GET_CURRENT_DEALER_STOCK_IMAGES_SUCCESS";

export const DELETE_CURRENT_DEALER_STOCK_IMAGE_REQUEST = "DELETE_CURRENT_DEALER_STOCK_IMAGE_REQUEST";
export const DELETE_CURRENT_DEALER_STOCK_IMAGE_ERROR = "DELETE_CURRENT_DEALER_STOCK_IMAGE_ERROR";
export const DELETE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS = "DELETE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS";

export const CREATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST = "CREATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST";
export const CREATE_CURRENT_DEALER_STOCK_IMAGE_ERROR = "CREATE_CURRENT_DEALER_STOCK_IMAGE_ERROR";
export const CREATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS = "CREATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS";

export const UPDATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST = "UPDATE_CURRENT_DEALER_STOCK_IMAGE_REQUEST";
export const UPDATE_CURRENT_DEALER_STOCK_IMAGE_ERROR = "UPDATE_CURRENT_DEALER_STOCK_IMAGE_ERROR";
export const UPDATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS = "UPDATE_CURRENT_DEALER_STOCK_IMAGE_SUCCESS";

export const DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST = "DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST";
export const DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR = "DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR";
export const DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS = "DELETE_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS";

export const PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST = "PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST";
export const PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR = "PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_ERROR";
export const PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS = "PATCH_ADDRESS_OF_CURRENT_DEALER_REQUEST_SUCCESS";

export const GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST =
  "GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST";
export const GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_ERROR =
  "GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_ERROR";
export const GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS =
  "GET_AUTHORIZED_DEALER_EMAIL_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS";

export const GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST =
  "GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST";
export const GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_ERROR =
  "GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_ERROR";
export const GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS =
  "GET_AUTHORIZED_DEALER_PHONE_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS";

export const GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST =
  "GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST";
export const GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_ERROR =
  "GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_ERROR";
export const GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS =
  "GET_AUTHORIZED_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_REQUEST_SUCCESS";

export const GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST = "GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST";
export const GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_ERROR = "GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_ERROR";
export const GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_SUCCESS = "GET_CURRENT_DEALER_EMAIL_ENQUIRIES_REQUEST_SUCCESS";

export const GET_CURRENT_DEALER_DELIVERY_FOR_CHART_REQUEST = "GET_CURRENT_DEALER_DELIVERY_FOR_CHART_REQUEST";
export const GET_CURRENT_DEALER_DELIVERY_FOR_CHART_ERROR = "GET_CURRENT_DEALER_DELIVERY_FOR_CHART_ERROR";
export const GET_CURRENT_DEALER_DELIVERY_FOR_CHART_SUCCESS = "GET_CURRENT_DEALER_DELIVERY_FOR_CHART_SUCCESS";

export const GET_CURRENT_DEALER_DELIVERY_ENQUIRIES_REQUEST = "GET_CURRENT_DEALER_DELIVERY_ENQUIRIES_REQUEST";
export const GET_CURRENT_DEALER_DELIVERY_REQUEST_ERROR = "GET_CURRENT_DEALER_DELIVERY_REQUEST_ERROR";
export const GET_CURRENT_DEALER_DELIVERY_REQUEST_SUCCESS = "GET_CURRENT_DEALER_DELIVERY_REQUEST_SUCCESS";

export const GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST = "GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST";
export const GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_ERROR = "GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_ERROR";
export const GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_SUCCESS = "GET_CURRENT_DEALER_PHONE_ENQUIRIES_REQUEST_SUCCESS";

export const GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST = "GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST";
export const GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_ERROR =
  "GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_ERROR";
export const GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_SUCCESS =
  "GET_CURRENT_DEALER_CALLBACK_ENQUIRIES_REQUEST_SUCCESS";

export const MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST = "MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST";
export const MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_ERROR = "MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_ERROR";
export const MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_SUCCESS = "MARK_EMAIL_ENQUIRY_AS_SPAM_REQUEST_SUCCESS";

export const MARK_EMAIL_ENQUIRY_AS_READ_REQUEST = "MARK_EMAIL_ENQUIRY_AS_READ_REQUEST";
export const MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_ERROR = "MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_ERROR";
export const MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_SUCCESS = "MARK_EMAIL_ENQUIRY_AS_READ_REQUEST_SUCCESS";

export const MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST = "MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST";
export const MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_ERROR = "MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_ERROR";
export const MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_SUCCESS = "MARK_CALLBACK_ENQUIRY_AS_READ_REQUEST_SUCCESS";

export const ADD_DEALER_SPECIALITY_REQUEST = "ADD_DEALER_SPECIALITY_REQUEST";
export const ADD_DEALER_SPECIALITY_SUCCESS = "ADD_DEALER_SPECIALITY_SUCCESS";
export const ADD_DEALER_SPECIALITY_ERROR = "ADD_DEALER_SPECIALITY_ERROR";

export const REMOVE_DEALER_SPECIALITY_REQUEST = "REMOVE_DEALER_SPECIALITY_REQUEST";
export const REMOVE_DEALER_SPECIALITY_ERROR = "REMOVE_DEALER_SPECIALITY_ERROR";
export const REMOVE_DEALER_SPECIALITY_SUCCESS = "REMOVE_DEALER_SPECIALITY_SUCCESS";

export const GET_DEALER_SPECIALITIES_REQUEST = "GET_DEALER_SPECIALITIES_REQUEST";
export const GET_DEALER_SPECIALITIES_ERROR = "GET_DEALER_SPECIALITIES_ERROR";
export const GET_DEALER_SPECIALITIES_SUCCESS = "GET_DEALER_SPECIALITIES_SUCCESS";

export const TOGGLE_PROCESSING = "TOGGLE_PROFILE_PROCESSING";
export const TOGGLE_PROFILE_ADDRESS_PROCESSING = "TOGGLE_PROFILE_ADDRESS_PROCESSING";

export const TOGGLE_FAV_ITEMS_LOADING = "TOGGLE_FAV_ITEMS_LOADING";
export const TOGGLE_FAV_DEALERS_LOADING = "TOGGLE_FAV_DEALERS_LOADING";
export const TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING = "TOGGLE_DEALER_EMAIL_ENQUIRIES_LOADING";
export const TOGGLE_DEALER_DELIVERY_ENQUIRIES_LOADING = "TOGGLE_DEALER_DELIVERY_ENQUIRIES_LOADING";
export const TOGGLE_DEALER_PHONE_ENQUIRIES_LOADING = "TOGGLE_DEALER_PHONE_ENQUIRIES_LOADING";
export const TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING = "TOGGLE_DEALER_CALLBACK_ENQUIRIES_LOADING";

export const SET_ANTIQUE_IMAGE_REQUEST = "SET_ANTIQUE_IMAGE_REQUEST";
export const SET_ANTIQUE_IMAGE_SUCCESS = "SET_ANTIQUE_IMAGE_SUCCESS";
export const SET_ANTIQUE_IMAGE_ERROR = "SET_ANTIQUE_IMAGE_ERROR";

export const SEARCH_ARTISAN_REQUEST = "SEARCH_ARTISAN_REQUEST";
export const SEARCH_ARTISAN_SUCCESS = "SEARCH_ARTISAN_SUCCESS";
export const SEARCH_ARTISAN_ERROR = "SEARCH_ARTISAN_ERROR";

export const DELETE_ANTIQUE_IMAGE_REQUEST = "DELETE_ANTIQUE_IMAGE_REQUEST";
export const DELETE_ANTIQUE_IMAGE_SUCCESS = "DELETE_ANTIQUE_IMAGE_SUCCESS";
export const DELETE_ANTIQUE_IMAGE_ERROR = "DELETE_IMAGE_FOR_ANTIQUE_ERROR";

export const CREATE_ITEM_REQUEST = "CREATE_ITEM_REQUEST";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR";

export const EDIT_ITEM_REQUEST = "EDIT_ITEM_REQUEST";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_ERROR = "EDIT_ITEM_ERROR";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";

export const ROTATE_ANTIQUE_IMAGE_REQUEST = "ROTATE_ANTIQUE_IMAGE_REQUEST";
export const ROTATE_ANTIQUE_IMAGE_SUCCESS = "ROTATE_ANTIQUE_IMAGE_SUCCESS";
export const ROTATE_ANTIQUE_IMAGE_ERROR = "ROTATE_ANTIQUE_IMAGE_ERROR";
export const ROTATE_ANTIQUE_IMAGE_PROCESSING = "ROTATE_ANTIQUE_IMAGE_PROCESSING";

export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_ERROR = "GET_CURRENCIES_ERROR";

export const MAKE_MAIN_PHOTO_FOR_ANTIQUE = "MAKE_MAIN_PHOTO_FOR_ANTIQUE";
export const REMOVE_MAIN_PHOTO_FOR_ANTIQUE = "REMOVE_MAIN_PHOTO_FOR_ANTIQUE";

export const SHOW_LOADING = "SHOW_PROFILE_LOADING";
export const HIDE_LOADING = "HIDE_PROFILE_LOADING";

export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";
export const CLEAR_SELECTED_ANTIQUE = "CLEAR_SELECTED_ANTIQUE";

export const UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_REQUEST = "UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_REQUEST";
export const UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_ERROR = "UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_ERROR";
export const UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_SUCCESS = "UPLOAD_NEW_IMAGE_FOR_AUTHORIZED_USER_SUCCESS";

export const TOGGLE_DEALER_EMAIL_ENQUIRIES_FOR_CHART_LOADING = "TOGGLE_DEALER_EMAIL_ENQUIRIES_FOR_CHART_LOADING";
export const TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING = "TOGGLE_DEALER_PHONE_ENQUIRIES_FOR_CHART_LOADING";
export const TOGGLE_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_LOADING = "TOGGLE_DEALER_CALLBACK_ENQUIRIES_FOR_CHART_LOADING";

export const GET_ALL_EMAIL_PREFERENCES_REQUEST = "GET_ALL_EMAIL_PREFERENCES_REQUEST";
export const GET_ALL_EMAIL_PREFERENCES_REQUEST_ERROR = "GET_ALL_EMAIL_PREFERENCES_REQUEST_ERROR";
export const GET_ALL_EMAIL_PREFERENCES_REQUEST_SUCCESS = "GET_ALL_EMAIL_PREFERENCES_REQUEST_SUCCESS";

export const CREATE_NEW_EMAIL_PREFERENCE_REQUEST = "CREATE_NEW_EMAIL_PREFERENCE_REQUEST";
export const CREATE_NEW_EMAIL_PREFERENCE_REQUEST_ERROR = "CREATE_NEW_EMAIL_PREFERENCE_REQUEST_ERROR";
export const CREATE_NEW_EMAIL_PREFERENCE_REQUEST_SUCCESS = "CREATE_NEW_EMAIL_PREFERENCE_REQUEST_SUCCESS";

export const DELETE_EMAIL_PREFERENCE_REQUEST = "DELETE_EMAIL_PREFERENCE_REQUEST";
export const DELETE_EMAIL_PREFERENCE_REQUEST_ERROR = "DELETE_EMAIL_PREFERENCE_REQUEST_ERROR";
export const DELETE_EMAIL_PREFERENCE_REQUEST_SUCCESS = "DELETE_EMAIL_PREFERENCE_REQUEST_SUCCESS";

export const PATCH_EMAIL_PREFERENCE_REQUEST = "PATCH_EMAIL_PREFERENCE_REQUEST";
export const PATCH_EMAIL_PREFERENCE_REQUEST_ERROR = "PATCH_EMAIL_PREFERENCE_REQUEST_ERROR";
export const PATCH_EMAIL_PREFERENCE_REQUEST_SUCCESS = "PATCH_EMAIL_PREFERENCE_REQUEST_SUCCESS";

export const GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST = "GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST";
export const GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_ERROR = "GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_ERROR";
export const GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_SUCCESS = "GET_EMAIL_PREFERENCE_SEARCH_RESULT_REQUEST_SUCCESS";

export const TOGGLE_EMAIL_PREFERENCES_LOADING = "TOGGLE_EMAIL_PREFERENCES_LOADING";
export const TOGGLE_EMAIL_PREFERENCES_PROCESSING = "TOGGLE_EMAIL_PREFERENCES_PROCESSING";

export const GET_DEALER_INVOICES_REQUEST = "GET_DEALER_INVOICES_REQUEST";
export const GET_DEALER_INVOICES_REQUEST_ERROR = "GET_DEALER_INVOICES_REQUEST_ERROR";
export const GET_DEALER_INVOICES_REQUEST_SUCCESS = "GET_DEALER_INVOICES_REQUEST_SUCCESS";

export const TOGGLE_DEALER_INVOICES_LOADING = "TOGGLE_DEALER_INVOICES_LOADING";
export const TOGGLE_DEALER_INVOICES_PROCESSING = "TOGGLE_DEALER_INVOICES_PROCESSING";

export const SEARCH_EMAIL_ENQUIRIES_REQUEST = "SEARCH_EMAIL_ENQUIRIES_REQUEST";
export const SEARCH_EMAIL_ENQUIRIES_REQUEST_SUCCESS = "SEARCH_EMAIL_ENQUIRIES_REQUEST_SUCCESS";
export const SEARCH_EMAIL_ENQUIRIES_REQUEST_ERROR = "SEARCH_EMAIL_ENQUIRIES_REQUEST_ERROR";

export const SEARCH_PHONE_ENQUIRIES_REQUEST = "SEARCH_PHONE_ENQUIRIES_REQUEST";
export const SEARCH_PHONE_ENQUIRIES_REQUEST_SUCCESS = "SEARCH_PHONE_ENQUIRIES_REQUEST_SUCCESS";
export const SEARCH_PHONE_ENQUIRIES_REQUEST_ERROR = "SEARCH_PHONE_ENQUIRIES_REQUEST_ERROR";

export const SEARCH_CALLBACK_ENQUIRIES_REQUEST = "SEARCH_CALLBACK_ENQUIRIES_REQUEST";
export const SEARCH_CALLBACK_ENQUIRIES_REQUEST_SUCCESS = "SEARCH_CALLBACK_ENQUIRIES_REQUEST_SUCCESS";
export const SEARCH_CALLBACK_ENQUIRIES_REQUEST_ERROR = "SEARCH_CALLBACK_ENQUIRIES_REQUEST_ERROR";

export const SEARCH_DELIVERY_ENQUIRIES_REQUEST = "SEARCH_DELIVERY_ENQUIRIES_REQUEST";
export const SEARCH_DELIVERY_ENQUIRIES_REQUEST_SUCCESS = "SEARCH_DELIVERY_ENQUIRIES_REQUEST_SUCCESS";
export const SEARCH_DELIVERY_ENQUIRIES_REQUEST_ERROR = "SEARCH_DELIVERY_ENQUIRIES_REQUEST_ERROR";

export const CREATE_DEFAULT_TEXT_REQUEST = "CREATE_DEFAULT_TEXT_REQUEST";
export const CREATE_DEFAULT_TEXT_REQUEST_ERROR = "CREATE_DEFAULT_TEXT_REQUEST_ERROR";

export const GET_ALL_DEFAULT_TEXTS_REQUEST = "GET_ALL_DEFAULT_TEXTS_REQUEST";
export const GET_ALL_DEFAULT_TEXTS_REQUEST_SUCCESS = "GET_ALL_DEFAULT_TEXTS_REQUEST_SUCCESS";
export const GET_ALL_DEFAULT_TEXTS_REQUEST_ERROR = "GET_ALL_DEFAULT_TEXTS_REQUEST_ERROR";

export const DELETE_DEFAULT_TEXT_REQUEST = "DELETE_DEFAULT_TEXT_REQUEST";
export const DELETE_DEFAULT_TEXT_REQUEST_ERROR = "DELETE_DEFAULT_TEXT_REQUEST_ERROR";

export const GET_SINGLE_DEALER_TEXT_REQUEST = "GET_SINGLE_DEALER_TEXT_REQUEST";
export const GET_SINGLE_DEALER_TEXT_REQUEST_SUCCESS = "GET_SINGLE_DEALER_TEXT_REQUEST_SUCCESS";
export const GET_SINGLE_DEALER_TEXT_REQUEST_ERROR = "GET_SINGLE_DEALER_TEXT_REQUEST_ERROR";

export const PATCH_DEFAULT_TEXT_REQUEST = "PATCH_DEFAULT_TEXT_REQUEST";
export const PATCH_DEFAULT_TEXT_REQUEST_ERROR = "PATCH_DEFAULT_TEXT_REQUEST_ERROR";

export const CREATE_DEALER_WEBSITE_REQUEST = "CREATE_DEALER_WEBSITE_REQUEST";
export const CREATE_DEALER_WEBSITE_REQUEST_SUCCESS = "CREATE_DEALER_WEBSITE_REQUEST_SUCCESS";
export const CREATE_DEALER_WEBSITE_REQUEST_ERROR = "CREATE_DEALER_WEBSITE_REQUEST_ERROR";

export const UPDATE_DEALER_WEBSITE_REQUEST = "UPDATE_DEALER_WEBSITE_REQUEST";
export const UPDATE_DEALER_WEBSITE_REQUEST_SUCCESS = "UPDATE_DEALER_WEBSITE_REQUEST_SUCCESS";
export const UPDATE_DEALER_WEBSITE_REQUEST_ERROR = "UPDATE_DEALER_WEBSITE_REQUEST_ERROR";

export const GET_DEALER_WEBSITE_REQUEST = "GET_DEALER_WEBSITE_REQUEST";
export const GET_DEALER_WEBSITE_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_REQUEST_ERROR = "GET_DEALER_WEBSITE_REQUEST_ERROR";

export const CREATE_DEALER_WEBSITE_INFO_REQUEST = "CREATE_DEALER_WEBSITE_INFO_REQUEST";
export const CREATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS = "CREATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS";
export const CREATE_DEALER_WEBSITE_INFO_REQUEST_ERROR = "CREATE_DEALER_WEBSITE_INFO_REQUEST_ERROR";

export const UPDATE_DEALER_WEBSITE_INFO_REQUEST = "UPDATE_DEALER_WEBSITE_INFO_REQUEST";
export const UPDATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS = "UPDATE_DEALER_WEBSITE_INFO_REQUEST_SUCCESS";
export const UPDATE_DEALER_WEBSITE_INFO_REQUEST_ERROR = "UPDATE_DEALER_WEBSITE_INFO_REQUEST_ERROR";

export const GET_DEALER_WEBSITE_INFO_REQUEST = "GET_DEALER_WEBSITE_INFO_REQUEST";
export const GET_DEALER_WEBSITE_INFO_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_INFO_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_INFO_REQUEST_ERROR = "GET_DEALER_WEBSITE_INFO_REQUEST_ERROR";

export const SEND_DEALER_WEBSITE_DOMAIN_REQUEST = "SEND_DEALER_WEBSITE_DOMAIN_REQUEST";
export const SEND_DEALER_WEBSITE_DOMAIN_REQUEST_SUCCESS = "SEND_DEALER_WEBSITE_DOMAIN_REQUEST_SUCCESS";
export const SEND_DEALER_WEBSITE_DOMAIN_REQUEST_ERROR = "SEND_DEALER_WEBSITE_DOMAIN_REQUEST_ERROR";

export const CREATE_DEALER_WEBSITE_PAGE_REQUEST = "CREATE_DEALER_WEBSITE_PAGE_REQUEST";
export const CREATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS = "CREATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS";
export const CREATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR = "CREATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR";

export const UPDATE_DEALER_WEBSITE_PAGE_REQUEST = "UPDATE_DEALER_WEBSITE_PAGE_REQUEST";
export const UPDATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS = "UPDATE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS";
export const UPDATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR = "UPDATE_DEALER_WEBSITE_PAGE_REQUEST_ERROR";

export const UPDATE_DEALER_WEBSITE_BLOG_REQUEST = "UPDATE_DEALER_WEBSITE_BLOG_REQUEST";
export const UPDATE_DEALER_WEBSITE_BLOG_REQUEST_SUCCESS = "UPDATE_DEALER_WEBSITE_BLOG_REQUEST_SUCCESS";
export const UPDATE_DEALER_WEBSITE_BLOG_REQUEST_ERROR = "UPDATE_DEALER_WEBSITE_BLOG_REQUEST_ERROR";

export const GET_DEALER_WEBSITE_PAGE_REQUEST = "GET_DEALER_WEBSITE_PAGE_REQUEST";
export const GET_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_PAGE_REQUEST_ERROR = "GET_DEALER_WEBSITE_PAGE_REQUEST_ERROR";

export const DELETE_DEALER_WEBSITE_PAGE_REQUEST = "DELETE_DEALER_WEBSITE_PAGE_REQUEST";
export const DELETE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS = "DELETE_DEALER_WEBSITE_PAGE_REQUEST_SUCCESS";
export const DELETE_DEALER_WEBSITE_PAGE_REQUEST_ERROR = "DELETE_DEALER_WEBSITE_PAGE_REQUEST_ERROR";

export const CLEAR_DEALER_WEBSITE_PAGE = "CLEAR_DEALER_WEBSITE_PAGE";

export const GET_DEALER_WEBSITE_PAGES_LIST_REQUEST = "GET_DEALER_WEBSITE_PAGES_LIST_REQUEST";
export const GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_ERROR = "GET_DEALER_WEBSITE_PAGES_LIST_REQUEST_ERROR";

export const GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST = "GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST";
export const GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_ERROR = "GET_DEALER_WEBSITE_BLOGS_LIST_REQUEST_ERROR";

export const PUBLISH_DEALER_WEBSITE_REQUEST = "PUBLISH_DEALER_WEBSITE_REQUEST";
export const PUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS = "PUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS";
export const PUBLISH_DEALER_WEBSITE_REQUEST_ERROR = "PUBLISH_DEALER_WEBSITE_REQUEST_ERROR";

export const PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST = "PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST";
export const PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_SUCCESS = "PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_SUCCESS";
export const PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_ERROR = "PUBLISH_DECLINED_DEALER_WEBSITE_REQUEST_ERROR";

export const UNPUBLISH_DEALER_WEBSITE_REQUEST = "UNPUBLISH_DEALER_WEBSITE_REQUEST";
export const UNPUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS = "UNPUBLISH_DEALER_WEBSITE_REQUEST_SUCCESS";
export const UNPUBLISH_DEALER_WEBSITE_REQUEST_ERROR = "UNPUBLISH_DEALER_WEBSITE_REQUEST_ERROR";

export const GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST = "GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST";
export const GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_SUCCESS = "GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_SUCCESS";
export const GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_ERROR = "GET_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST_ERROR";

export const DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST = "DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST";
export const DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS = "DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS";
export const DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR = "DELETE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR";

export const UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST = "UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST";
export const UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS = "UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_SUCCESS";
export const UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR = "UPDATE_DEALER_WEBSITE_SUBSCRIPTION_REQUEST_ERROR";

export const EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST = "EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_REQUEST";
export const EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_SUCCESS = "EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_SUCCESS";
export const EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_ERROR = "EXPORT_DEALER_WEBSITE_SUBSCRIPTIONS_ERROR";

export const CLEAR_SELECTED_DEFAULT_TEXT = "CLEAR_SELECTED_DEFAULT_TEXT";

export const SET_NEW_IMAGES_ORDER = "SET_NEW_IMAGES_ORDER";

export const CLEAR_DATA_ERROR = "CLEAR_DATA_ERROR";

export const SHOW_MARKETPLACES_LOADING = "SHOW_MARKETPLACES_LOADING";
export const HIDE_MARKETPLACES_LOADING = "HIDE_MARKETPLACES_LOADING";

export const GET_MARKETPLACES_REQUEST = "GET_MARKETPLACES_REQUEST";
export const GET_MARKETPLACES_REQUEST_ERROR = "GET_MARKETPLACES_REQUEST_ERROR";
export const GET_MARKETPLACES_REQUEST_SUCCESS = "GET_MARKETPLACES_REQUEST_SUCCESS";

export const CONNECT_MARKETPLACE_REQUEST = "CONNECT_MARKETPLACE_REQUEST";
export const CONNECT_MARKETPLACE_REQUEST_ERROR = "CONNECT_MARKETPLACE_REQUEST_ERROR";

export const SHOW_IMAGES_LOADING = "SHOW_IMAGES_LOADING";
export const HIDE_IMAGES_LOADING = "HIDE_IMAGES_LOADING";

export const GET_SUGGESTED_CATEGORIES_REQUEST = "GET_SUGGESTED_CATEGORIES_REQUEST";
export const GET_SUGGESTED_CATEGORIES_REQUEST_SUCCESS = "GET_SUGGESTED_CATEGORIES_REQUEST_SUCCESS";
export const GET_SUGGESTED_CATEGORIES_REQUEST_ERROR = "GET_SUGGESTED_CATEGORIES_REQUEST_ERROR";

export const GET_HOLIDAY_MODE_DETAILS_REQUEST = "GET_HOLIDAY_MODE_DETAILS_REQUEST";
export const GET_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS = "GET_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS";
export const GET_HOLIDAY_MODE_DETAILS_REQUEST_ERROR = "GET_HOLIDAY_MODE_DETAILS_REQUEST_ERROR";

export const MODIFY_HOLIDAY_MODE_DETAILS_REQUEST = "MODIFY_HOLIDAY_MODE_DETAILS_REQUEST";
export const MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS = "MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_SUCCESS";
export const MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_ERROR = "MODIFY_HOLIDAY_MODE_DETAILS_REQUEST_ERROR";

export const GET_TRADE_ASSOCIATIONS_REQUEST = "GET_TRADE_ASSOCIATIONS_REQUEST";
export const GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS = "GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS";
export const GET_TRADE_ASSOCIATIONS_REQUEST_ERROR = "GET_TRADE_ASSOCIATIONS_REQUEST_ERROR";

export const GET_DEALER_TRADE_ASSOCIATIONS_REQUEST = "GET_DEALER_TRADE_ASSOCIATIONS_REQUEST";
export const GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_SUCCESS = "GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_SUCCESS";
export const GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR = "GET_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR";

export const CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST = "CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST";
export const CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR = "CREATE_DEALER_TRADE_ASSOCIATIONS_REQUEST_ERROR";

export const CLEAR_DEALER_TRADE_ASSOCIATIONS = "CLEAR_DEALER_TRADE_ASSOCIATIONS";

export const GET_ONLINE_SALES_STATISTICS_REQUEST = "GET_ONLINE_SALES_STATISTICS_REQUEST";
export const GET_ONLINE_SALES_STATISTICS_REQUEST_SUCCESS = "GET_ONLINE_SALES_STATISTICS_REQUEST_SUCCESS";
export const GET_ONLINE_SALES_STATISTICS_REQUEST_ERROR = "GET_ONLINE_SALES_STATISTICS_REQUEST_ERROR";
