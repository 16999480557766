import { v4 as uuidv4 } from "uuid";

export const getUnregisteredFavourites = () => {
  try {
    const storedValue = localStorage.getItem("unregisteredFavourites") || "[]";
    const items = JSON.parse(storedValue);

    return items && items.length ? items : [];
  } catch (e) {
    console.error(e);

    return [];
  }
};

export const setUnregisteredFavourites = (items) => {
  try {
    const convertedItems = items.map((item) => {
      const image = item.images.find(({ isMain }) => isMain) || item.images[0];

      return { ...item, image };
    });

    localStorage.setItem("unregisteredFavourites", JSON.stringify(convertedItems));

    return convertedItems;
  } catch (e) {
    console.error(e)
  }
};

export const getAlgoliaUserUUID = () => {
  let storedValue = localStorage.getItem("algoliaUserUUID");

  if (!storedValue) {
    storedValue = `${uuidv4()}`;
    localStorage.setItem("algoliaUserUUID", storedValue);
  }

  return storedValue;
};
