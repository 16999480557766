export const SCOTLAND_OPTIONS = [
  {
    place_id: null,
    county: "City of Aberdeen",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "city-of-aberdeen",
    latitude: 57.05268,
    longitude: -1.9026,
    boundingBox: [-2.74521, 57.27162, -1.06018, 56.83245],
    zoom: 10
  },
  {
    place_id: null,
    county: "Aberdeenshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "aberdeenshire",
    latitude: 56.93299,
    longitude: -2.02972,
    boundingBox: [-5.39978, 57.80380, 1.34033, 56.04136],
    zoom: 8
  },
  {
    place_id: null,
    county: "Angus (Forfarshire)",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "angus-forfarshire",
    latitude: 56.57310,
    longitude: -2.61063,
    boundingBox: [-4.29565, 57.01532, -0.92560, 56.12565],
    zoom: 9
  },
  {
    place_id: null,
    county: "Argyll and Bute",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "argyll",
    latitude: 55.43525,
    longitude: -4.71863,
    boundingBox: [-11.45874, 57.22556, 2.02148, 53.55989],
    zoom: 7
  },
  {
    place_id: null,
    county: "Ayrshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "ayrshire",
    latitude: 55.09409,
    longitude: -3.72162,
    boundingBox: [-7.19055, 56.00760, -0.25269, 54.15922],
    zoom: 8
  },
  {
    place_id: null,
    county: "Banffshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "banffshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Berwickshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "berwickshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Bute",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "bute",
    latitude: 55.74953,
    longitude: -4.96513,
    boundingBox: [-5.80765, 55.97610, -4.12262, 55.52163],
    zoom: 10
  },
  {
    place_id: null,
    county: "Caithness",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "caithness",
    latitude: 58.28495,
    longitude: -3.22861,
    boundingBox: [-4.91364, 58.70691, -1.54358, 57.85790],
    zoom: 9
  },
  {
    place_id: null,
    county: "Clackmannanshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "clackmannanshire",
    latitude: 56.10919,
    longitude: -3.69381,
    boundingBox: [-4.11507, 56.22160, -3.27255, 55.99646],
    zoom: 11
  },
  {
    place_id: null,
    county: "Cromartyshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "cromartyshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Dumfries and Galloway",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "dumfries-and-galloway",
    zoom: 8
  },
  {
    place_id: null,
    county: "Dunbartonshire (Dumbarton)",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "dunbartonshire-dumbarton",
    latitude: 55.90073,
    longitude: -4.35951,
    boundingBox: [-5.20203, 56.12642, -3.51700, 55.67371],
    zoom: 10
  },
  {
    place_id: null,
    county: "Dundee City",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "dundee-city",
    latitude: 56.45756,
    longitude: -2.90640,
    boundingBox: [-3.11703, 56.51329, -2.69577, 56.40174],
    zoom: 8
  },
  {
    place_id: null,
    county: "East Ayrshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "east-ayrshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "East Dunbartonshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "east-dunbartonshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "East Lothian (Haddingtonshire)",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "east-lothian-haddingtonshire",
    latitude: 55.87608,
    longitude: -2.60307,
    boundingBox: [-3.44559, 56.10192, -1.76056, 55.6489],
    zoom: 10
  },
  {
    place_id: null,
    county: "East Renfrewshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "east-renfrewshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "City of Edinburgh",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "city-of-edinburgh",
    latitude: 55.84140,
    longitude: -3.13042,
    boundingBox: [-3.97293, 56.06744, -2.28790, 55.61404],
    zoom: 10
  },
  {
    place_id: null,
    county: "Falkirk",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "falkirk",
    zoom: 8
  },
  {
    place_id: null,
    county: "Fife",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "fife",
    latitude: 56.05977,
    longitude: -2.88803,
    boundingBox: [-4.57306, 56.50799, -1.20300, 55.60628],
    zoom: 9
  },
  {
    place_id: null,
    county: "Glasgow City",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "glasgow-city",
    latitude: 55.81942,
    longitude: -4.19369,
    boundingBox: [-4.61494, 55.93266, -3.77243, 55.70584],
    zoom: 11
  },
  {
    place_id: null,
    county: "Highland",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "highland",
    zoom: 8
  },
  {
    place_id: null,
    county: "Inverclyde",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "inverclyde",
    zoom: 8
  },
  {
    place_id: null,
    county: "Inverness-shire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "inverness-shire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Kincardineshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "kincardineshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Kinross-shire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "kinross-shire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Kirkcudbrightshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "kirkcudbrightshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Lanarkshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "lanarkshire",
    latitude: 55.50085,
    longitude: -3.14481,
    boundingBox: [-6.61374, 56.40499, 0.32412, 54.57546],
    zoom: 8
  },
  {
    place_id: null,
    county: "Midlothian",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "midlothian",
    latitude: 55.74257,
    longitude: -2.94228,
    boundingBox: [-3.78479, 55.96919, -2.09976, 55.51464],
    zoom: 10
  },
  {
    place_id: null,
    county: "Moray (Elginshire)",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "moray-elginshire",
    latitude: 57.13922,
    longitude: -2.69440,
    boundingBox: [-6.06445, 58.00519, 0.67566, 56.25251],
    zoom: 8
  },
  {
    place_id: null,
    county: "Nairnshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "nairnshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Na h-Eileanan Siar",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "na-h-eileanan-siar",
    zoom: 8
  },
  {
    place_id: null,
    county: "North Ayrshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "north-ayrshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "North Lanarkshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "north-lanarkshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Orkney Islands",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "orkney-islands",
    latitude: 59.04116,
    longitude: -2.90318,
    boundingBox: [-5.65247, 59.60110, -0.15930, 58.47072],
    zoom: 8
  },
  {
    place_id: null,
    county: "Perth and Kinross",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "perth-and-kinross",
    zoom: 8
  },
  {
    place_id: null,
    county: "Peeblesshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "peeblesshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Perthshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "perthshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Renfrewshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "renfrewshire",
    latitude: 55.84801,
    longitude: -4.56856,
    boundingBox: [-5.25558, 56.00222, -3.88229, 55.69384],
    zoom: 10
  },
  {
    place_id: null,
    county: "Ross and Cromarty",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "ross-and-cromarty",
    zoom: 8
  },
  {
    place_id: null,
    county: "Ross-shire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "ross-shire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Roxburghshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "roxburghshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Scottish Borders",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "scottish-borders",
    zoom: 8
  },
  {
    place_id: null,
    county: "Shetland Islands",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "shetland-islands",
    zoom: 8
  },
  {
    place_id: null,
    county: "South Ayrshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "south-ayrshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "South Lanarkshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "south-lanarkshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Selkirkshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "selkirkshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Shetland (Zetland)",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "shetland-zetland",
    latitude: 60.24829,
    longitude: -1.37657,
    boundingBox: [-4.12537, 60.78866, 1.36780, 59.69824],
    zoom: 8
  },
  {
    place_id: null,
    county: "Stirlingshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "stirlingshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Sutherland",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "sutherland",
    zoom: 8
  },
  {
    place_id: null,
    county: "West Dunbartonshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "west-dunbartonshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "West Lothian",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "west-lothian",
    latitude: 55.88829,
    longitude: -3.60894,
    boundingBox: [4.29565, 56.04213, -2.92236, 55.73407],
    zoom: 10
  },
  {
    place_id: null,
    county: "Wigtownshire",
    state_district: null,
    state: "Scotland",
    country_code: "gb",
    url: "wigtownshire",
    zoom: 8
  },
];
