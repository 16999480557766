export const WALES_OPTIONS = [
  {
    place_id: null,
    county: "Anglesey",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "anglesey",
    latitude: 53.20274,
    longitude: -4.20158,
    boundingBox: [5.06470, 53.44390, -3.33847, 52.96022],
    zoom: 10
  },
  {
    place_id: null,
    county: "Blaenau Gwent",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "blaenau-went",
    zoom: 8
  },
  {
    place_id: null,
    county: "Brecknockshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "brecknockshire",
    latitude: 51.83917,
    longitude: -3.14072,
    boundingBox: [-4.86694, 52.33534, -1.41449, 51.33748],
    zoom: 9
  },
  {
    place_id: null,
    county: "Bridgend",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "bridgend",
    zoom: 8
  },
  {
    place_id: null,
    county: "Caerphilly",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "caerphilly",
    zoom: 8
  },
  {
    place_id: null,
    county: "Caernarfonshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "caernarfonshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Cardiganshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "cardiganshire",
    latitude: 52.12843,
    longitude: -3.74496,
    boundingBox: [-5.47119, 52.62139, -2.01874, 51.62995],
    zoom: 9
  },
  {
    place_id: null,
    county: "Cardiff",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "cardiff",
    zoom: 8
  },
  {
    place_id: null,
    county: "Carmarthenshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "carmarthenshire",
    latitude: 51.72022,
    longitude: -3.87955,
    boundingBox: [-5.60577, 52.21770, -2.15332, 51.21721],
    zoom: 9
  },
  {
    place_id: null,
    county: "Ceredigion",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "ceredigion",
    zoom: 8
  },
  {
    place_id: null,
    county: "Clwyd",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "clwyd",
    latitude: 53.10577,
    longitude: -3.23874,
    boundingBox: [-3.45451, 53.16633, -3.02296, 53.04513],
    zoom: 12
  },
  {
    place_id: null,
    county: "Conwy",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "conwy",
    zoom: 8
  },
  {
    place_id: null,
    county: "Denbighshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "denbighshire",
    latitude: 52.95195,
    longitude: -3.11874,
    boundingBox: [-4.84497, 53.43572, -1.39252, 52.46270],
    zoom: 9
  },
  {
    place_id: null,
    county: "Dyfed",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "dyfed",
    zoom: 8
  },
  {
    place_id: null,
    county: "Flintshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "flintshire",
    latitude: 53.13936,
    longitude: -2.99721,
    boundingBox: [-3.86032, 53.38087, -2.13409, 52.89648],
    zoom: 10
  },
  {
    place_id: null,
    county: "Glamorgan",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "glamorgan",
    latitude: 51.40649,
    longitude: -3.29967,
    boundingBox: [-3.73123, 51.53224, -2.86812, 51.28039],
    zoom: 11
  },
  {
    place_id: null,
    county: "Gwent",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "gwent",
    latitude: 51.71001,
    longitude: -3.09986,
    boundingBox: [-3.53142, 51.83493, -2.66830, 51.58475],
    zoom: 11
  },
  {
    place_id: null,
    county: "Gwynedd",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "gwynedd",
    latitude: 52.55632,
    longitude: -3.36456,
    boundingBox: [-6.81702, 53.52725, 0.08789, 51.56341],
    zoom: 8
  },
  {
    place_id: null,
    county: "Isle of Anglesey",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "isle-of-anglesey",
    zoom: 8
  },
  {
    place_id: null,
    county: "Merionethshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "merionethshire",
    latitude: 52.64306,
    longitude: -3.55545,
    boundingBox: [-5.28168, 53.13029, -1.82922, 52.15034],
    zoom: 9
  },
  {
    place_id: null,
    county: "Merthyr Tydfil",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "merthyr-tydfil",
    zoom: 8
  },
  {
    place_id: null,
    county: "Mid Glamorgan",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "mid-glamorgan",
    latitude: 51.44202,
    longitude: -3.15994,
    boundingBox: [-4.88617, 51.94257, -1.43372, 50.93593],
    zoom: 9
  },
  {
    place_id: null,
    county: "Monmouthshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "monmouthshire",
    latitude: 51.55829,
    longitude: -2.61063,
    boundingBox: [-4.33685, 52.05756, -0.88440, 51.05348],
    zoom: 9
  },
  {
    place_id: null,
    county: "Montgomeryshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "montgomeryshire",
    latitude: 52.45601,
    longitude: -3.16544,
    boundingBox: [-4.89166, 52.94533, -1.43921, 51.96119],
    zoom: 9
  },
  {
    place_id: null,
    county: "Neath Port Talbot",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "neath-port-talbot",
    zoom: 8
  },
  {
    place_id: null,
    county: "Newport",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "newport",
    zoom: 8
  },
  {
    place_id: null,
    county: "Pembrokeshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "pembrokeshire",
    latitude: 51.68788,
    longitude: -4.61014,
    boundingBox: [-6.33636, 52.18572, -2.88391, 51.18451],
    zoom: 9
  },
  {
    place_id: null,
    county: "Powys",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "powys",
    latitude: 51.99841,
    longitude: -2.84821,
    boundingBox: [-6.30066, 52.98172, 0.60425, 50.99301],
    zoom: 8
  },
  {
    place_id: null,
    county: "Radnorshire",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "radnorshire",
    latitude: 52.08457,
    longitude: -3.00888,
    boundingBox: [-4.73511, 52.57802, -1.28265, 51.58560],
    zoom: 9
  },
  {
    place_id: null,
    county: "Rhondda Cynon Taf",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "rhondda-cynon-taf",
    zoom: 8
  },
  {
    place_id: null,
    county: "South Glamorgan",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "south-glamorgan",
    latitude: 51.41291,
    longitude: -3.23341,
    boundingBox: [-4.09653, 51.66404, -2.37030, 51.16040],
    zoom: 10
  },
  {
    place_id: null,
    county: "Swansea",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "swansea",
    zoom: 8
  },
  {
    place_id: null,
    county: "Torfaen",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "torfaen",
    zoom: 8
  },
  {
    place_id: null,
    county: "Vale of Glamorgan",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "vale-of-glamorgan",
    zoom: 8
  },
  {
    place_id: null,
    county: "West Glamorgan",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "west-glamorgan",
    latitude: 51.58390,
    longitude: -3.77449,
    boundingBox: [-4.61700, 51.83408 - 2.93198, 51.33233],
    zoom: 10
  },
  {
    place_id: null,
    county: "Wrexham",
    state_district: null,
    state: "Wales",
    country_code: "gb",
    url: "wrexham",
    latitude: 52.91967,
    longitude: -2.85988,
    boundingBox: [3.72299, 53.16242, -1.99677, 52.67555],
    zoom: 10
  }
];
