import * as React from "react";
import PropTypes from "prop-types";

function SvgBellBadgeOutlineIcon({ fillBadge = "#F44336" }) {
  return (
    <svg height="1em" width="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z" />
      <circle cx="19" cy="5" r="4.375" fill={fillBadge} stroke="white" strokeWidth="1.25" />
    </svg>
  );
}

SvgBellBadgeOutlineIcon.propTypes = {
  fillBadge: PropTypes.string,
};

export default SvgBellBadgeOutlineIcon;
