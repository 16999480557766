import fetch from "./customFetch";


const favorites = {
  async getFavoriteGroups(options = {}) {
    const { ordering, page = 1, pageSize = 20 } = options;

    const query = new URLSearchParams();

    if (ordering) {
      query.set("ordering", ordering);
    }

    if (page) {
      query.set("page", page);
    }

    if (pageSize) {
      query.set("page_size", pageSize);
    }

    try {
      return await fetch({
        url: `/profiles/favorite-items-group/?${query.toString()}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async postFavoriteGroups(data) {
    try {
      return await fetch({
        url: "/profiles/favorite-items-group/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async putFavoriteGroups(id, data) {
    try {
      return await fetch({
        url: `/profiles/favorite-items-group/${id}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async deleteFavoriteGroups(id) {
    try {
      return await fetch({
        url: `/profiles/favorite-items-group/${id}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async addItemToFavoriteGroups(ref, data) {
    try {
      return await fetch({
        url: `/items/${ref}/add/`,
        method: "POST",
        data,
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async removeItemFromFavorite(data) {
    try {
      return await fetch({
        url: "/profiles/favorite-items-group/remove/",
        method: "POST",
        data,
      });
    }
    catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },
}

export default favorites;
