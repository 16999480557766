import * as types from "./authActionsTypes";


//set auth state of application: true = is logged in, false = no user is logged in
export const loginRequest = (data, callback) => {
  return { type: types.LOGIN_REQUEST, data, callback };
};

export const logoutRequest = () => {
  return { type: types.LOGOUT_REQUEST };
};

export const openLoginModalRequest = () => {
  return { type: types.OPEN_LOGIN_MODAL };
};

export const closeLoginModalRequest = () => {
  return { type: types.CLOSE_LOGIN_MODAL };
};

export const registerVisitorRequest = (data) => {
  return { type: types.REGISTER_VISITOR_REQUEST, data };
};

export const registerTraderRequest = (data) => {
  return { type: types.REGISTER_TRADER_REQUEST, data };
};

export const registerDealerRequest = (data, callback) => {
  return { type: types.REGISTER_DEALER_REQUEST, data, callback };
};

export const activateAccountRequest = (data) => {
  return { type: types.ACTIVATE_ACCOUNT_REQUEST, data };
};

export const resetAuthErrorAction = () => {
  return { type: types.RESET_ACTIVATION_ERROR_ACTION };
};

export const closeNotificationModalRequest = (payload) => {
  return { type: types.CLOSE_NOTIFICATION_MODAL_REQUEST, payload };
};

export const getGoogleCaptchaKeyRequest = () => {
  return { type: types.GET_GOOGLE_CAPTCHA_KEY_REQUEST };
};

export const setIsCookiesAccepted = (payload) => {
  return { type: types.SET_IS_COOKIES_ACCEPTED_ACTION, payload };
};

export const toggleAdvertiseWBModalAction = (payload) => {
  return { type: types.TOGGLE_ADVERTISE_WB_MODAL_ACTION, payload };
};
