export const transformPhoneNumber = ({ customerPhone, countryCode }) => {
  if ((countryCode === "GB" && customerPhone.split(" ").join(" ").length > 7) || customerPhone.length > 1) {
    if (countryCode === "GB" && customerPhone.slice(0, 3) !== "+44") {
      return customerPhone[0] === "0"
        ? `+44${customerPhone.slice(1)}`
        : `+44${customerPhone}`
    }

    return `+${customerPhone}`.replace("++", "+");
  }

  return null;
}
