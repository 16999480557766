import Cookies from "universal-cookie";

import * as types from "./authActionsTypes";
import {
  GET_AUTHORIZED_USER_PROFILE_REQUEST_ERROR,
  GET_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS,
  PATCH_AUTHORIZED_USER_PROFILE_REQUEST_ERROR,
  PATCH_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS,
} from "../profiles/profilesActionsTypes";
import {
  NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS,
  NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS,
  NOTIFICATION_DELETE_REQUEST_SUCCESS,
} from "../notifications/notificationsActionsTypes";
import {
  TOGGLE_ADVERTISE_WB_MODAL_ACTION,
  GET_GOOGLE_CAPTCHA_KEY_SUCCESS
} from "./authActionsTypes";

const cookie = new Cookies();
const isCookiesAccepted = !!cookie.get("cookiePolicyAccepted");
const isUserAuthenticated = !!cookie.get("accessToken");

export const initialState = {
  isAuthenticated: isUserAuthenticated,
  processing: false,
  error: null,
  loginError: null,
  showLoginModal: false,
  authorizedUserProfile: {},
  isAdvertiseWBModalOpened: false,
  googleCaptchaKey: null,
  isCookieAccepted: isCookiesAccepted,
};


const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };

    case GET_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        authorizedUserProfile: action.payload
      };

    case types.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        error: null,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        processing: false,
        authorizedUserProfile: {},
        error: null,
      };

    case types.CLOSE_NOTIFICATION_MODAL_SUCCESS:
      return {
        ...state,
        authorizedUserProfile: action.payload,
      }

    case types.OPEN_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: true,
      };

    case types.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: false,
        loginError: null,
      };

    case types.REGISTER_VISITOR_ERROR:
    case types.REGISTER_TRADER_REQUEST_ERROR:
    case types.REGISTER_DEALER_REQUEST_ERROR:
    case GET_AUTHORIZED_USER_PROFILE_REQUEST_ERROR:
    case PATCH_AUTHORIZED_USER_PROFILE_REQUEST_ERROR:
    case types.CLOSE_NOTIFICATION_MODAL_FAILED:
      return {
        ...state,
        error: action.error
      };
    case types.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        loginError: action.error,
      };

    case types.REGISTER_VISITOR_SUCCESS:
    case types.REGISTER_TRADER_REQUEST_SUCCESS:
    case types.REGISTER_DEALER_REQUEST_SUCCESS:
    case types.ACTIVATE_ACCOUNT_SUCCESS:
    case PATCH_AUTHORIZED_USER_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case types.ACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        activationError: action.error,
      };

    case types.RESET_ACTIVATION_ERROR_ACTION:
      return {
        ...state,
        activationError: null,
      };

    case types.SET_IS_COOKIES_ACCEPTED_ACTION:
      cookie.set(
        "cookiePolicyAccepted",
        action.payload.cookieValue,
        { path: "/", expires: action.payload.expirationDate }
      );

      return {
        ...state,
        isCookieAccepted: !!action.payload.cookieValue
      }

    case NOTIFICATION_MARK_AS_READ_REQUEST_SUCCESS:
      return {
        ...state,
        authorizedUserProfile: {
          ...state.authorizedUserProfile,
          unreadNotifications: action.payload.unreadNotifications,
        },
      };

    case NOTIFICATION_MARK_AS_UNREAD_REQUEST_SUCCESS:
      return {
        ...state,
        authorizedUserProfile: {
          ...state.authorizedUserProfile,
          unreadNotifications: action.payload.unreadNotifications,
        },
      };

    case NOTIFICATION_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        authorizedUserProfile: {
          ...state.authorizedUserProfile,
          unreadNotifications: action.payload.unreadNotifications,
        },
      };

    case GET_GOOGLE_CAPTCHA_KEY_SUCCESS:
      return {
        ...state,
        googleCaptchaKey: action.payload.token,
      };

    case TOGGLE_ADVERTISE_WB_MODAL_ACTION:
      return {
        ...state,
        isAdvertiseWBModalOpened: action.payload,
      };

    default:
      return state;
  }
};

export default auth;
