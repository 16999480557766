export const ENGLAND_PROPERTIES = {
  place_id: 271057451,
  country: "United Kingdom",
  country_code: "gb",
  state: "England",
  url: "england",
  latitude: 55,
  longitude: -5,
  boundingBox: [-23.99591, 59.60202, 16.87323, 42.45723],
  zoom: 5
};

export const ENGLAND_OPTIONS = [
  {
    place_id: null,
    county: "Avon",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "avon",
    latitude: 51.64915,
    longitude: -2.0352,
    boundingBox: [-2.7823, 51.99, -1.2881, 51.3083],
    zoom: 8
  },
  {
    place_id: 272789701,
    county: "Bedfordshire",
    state_district: "Cambridgeshire and Peterborough",
    state: "England",
    country_code: "gb",
    url: "bedfordshire",
    latitude: 52.59107,
    longitude: -0.312472,
    boundingBox: [-0.563441, 52.680754, -0.061503, 52.501386],
    zoom: 10
  },
  {
    place_id: 273687463,
    county: "Berkshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "berkshire",
    latitude: 51.45356,
    longitude: -1.03907,
    boundingBox: [-1.64795, 51.72192, -0.43121, 51.18279],
    zoom: 9
  },
  {
    place_id: 277069696,
    county: "City of Brighton and Hove",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "city-of-brighton-and-hove",
    latitude: 50.8459,
    longitude: -0.130147,
    boundingBox: [-0.263940, 50.901207, 0.003646, 50.790600],
    zoom: 11
  },
  {
    place_id: 276550638,
    county: "City of Bristol",
    state_district: "West of England",
    state: "England",
    country_code: "gb",
    url: "city-of-bristol",
    latitude: 51.46814,
    longitude: -2.63981,
    boundingBox: [-3.00408, 51.60268, -2.27555, 51.33320],
    zoom: 10
  },
  {
    place_id: 274224519,
    county: "Buckinghamshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "buckinghamshire",
    latitude: 51.79,
    longitude: -0.75,
    boundingBox: [-1.1406964, 52.0815218, -0.4766156, 51.4854689],
    zoom: 9
  },
  {
    place_id: 175553703,
    county: "Cambridgeshire",
    state_district: "Cambridgeshire and Peterborough",
    state: "England",
    country_code: "gb",
    url: "cambridgeshire",
    latitude: 52.37560,
    longitude: 0.00549,
    boundingBox: [-2.74109, 53.04121, 2.75208, 51.69980],
    zoom: 8
  },
  // {
  //   place_id: 172682781,
  //   county: "Cambridgeshire and Isle of Ely",
  //   state_district: "Cambridgeshire and Peterborough",
  //   state: "England",
  //   country_code: "gb",
  //   latitude: 52.401455,
  //   longitude: 0.316951,
  //   boundingBox: [0.197225, 52.447067, 0.439143, 52.360076],
  //   url: "cambridgeshire-and-isle-of-ely",
  //   zoom: 11
  // },
  {
    place_id: null,
    county: "Cheshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "cheshire",
    latitude: 52.99330,
    longitude: -2.36893,
    boundingBox: [-3.92487, 53.56968, -0.81299, 52.40912],
    zoom: 9
  },
  // {
  //   place_id: null,
  //   county: "Cleveland",
  //   state_district: null,
  //   state: "England",
  //   country_code: "gb",
  //   url: "cleveland",
  //   zoom: 8
  // },
  {
    place_id: null,
    county: "Cornwall",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "cornwall",
    latitude: 49.81318,
    longitude: -4.70490,
    boundingBox: [-7.81677, 51.04139, -1.59302, 48.55298],
    zoom: 8
  },
  {
    place_id: null,
    county: "Cumberland",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "cumberland",
    latitude: 54.18816,
    longitude: -2.83173,
    boundingBox: [-5.94360, 55.30101, 0.28015, 53.04452],
    zoom: 8
  },
  {
    place_id: null,
    county: "Cumbria",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "cumbria",
    latitude: 54.12382,
    longitude: -2.55157,
    boundingBox: [-5.66345, 55.23842, 0.56030, 52.97842],
    zoom: 8
  },
  {
    place_id: null,
    county: "Derbyshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "derbyshire",
    latitude: 52.58970,
    longitude: -1.20026,
    boundingBox: [-4.31213, 53.74546, 1.91162, 51.40263],
    zoom: 8
  },
  {
    place_id: null,
    county: "Devon",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "devon",
    latitude: 50.19448,
    longitude: -3.5733,
    boundingBox: [-6.68518, 51.41291, -0.46143, 48.94415],
    zoom: 8
  },
  {
    place_id: null,
    county: "Dorset",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "dorset",
    latitude: 50.79726,
    longitude: -2.30438,
    boundingBox: [-3.67767, 51.14317, -0.93109, 50.44876],
    zoom: 8
  },
  {
    place_id: null,
    county: "Durham",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "durham",
    latitude: 54.68653,
    longitude: -1.79901,
    boundingBox: [-3.17230, 55.00283, -0.42572, 54.36776],
    zoom: 9
  },
  {
    place_id: null,
    county: "East Suffolk",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "east-suffolk",
    latitude: 52.24207,
    longitude: 1.46336,
    boundingBox: [0.09064, 52.57635, 2.83722, 51.90361],
    zoom: 9
  },
  {
    place_id: null,
    county: "East Sussex",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "east-sussex",
    latitude: 50.94091,
    longitude: 0.31139,
    boundingBox: [-1.06293, 51.28597, 1.68365, 50.59370],
    zoom: 9
  },
  {
    place_id: null,
    county: "Essex",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "essex",
    latitude: 51.77172,
    longitude: 0.63927,
    boundingBox: [-0.73334, 52.10988, 2.01324, 51.43004],
    zoom: 9
  },
  {
    place_id: null,
    county: "Gloucestershire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "gloucestershire",
    latitude: 51.76444,
    longitude: -2.16431,
    boundingBox: [-4.91089, 52.43927, 0.58228, 51.07937],
    zoom: 8
  },
  {
    place_id: null,
    county: "Greater London",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "greater-london",
    latitude: 51.48993,
    longitude: -0.08789,
    boundingBox: [-1.46118, 51.83069, 1.28540, 51.14662],
    zoom: 9
  },
  {
    place_id: null,
    county: "Greater Manchester",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "greater-manchester",
    latitude: 53.50690,
    longitude: -2.32007,
    boundingBox: [-3.69415, 53.83308, -0.94757, 53.17970],
    zoom: 8
  },
  {
    place_id: null,
    county: "Hampshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "hampshire",
    latitude: 51.04607,
    longitude: -1.34331,
    boundingBox: [4.09241, 51.73043, 1.40076, 50.34897],
    zoom: 8
  },
  // {
  //   place_id: null,
  //   county: "Hereford and Worcester",
  //   state_district: null,
  //   state: "England",
  //   country_code: "gb",
  //   url: "hereford-and-worcester",
  //   zoom: 8
  // },
  {
    place_id: null,
    county: "Herefordshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "herefordshire",
    latitude: 52.11162,
    longitude: -2.73994,
    boundingBox: [-4.11438, 52.44764, -1.36780, 51.77294],
    zoom: 9
  },
  {
    place_id: null,
    county: "Hertfordshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "hertfordshire",
    zoom: 8
  },
  {
    place_id: null,
    county: "Humberside",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "humberside",
    zoom: 8
  },
  {
    place_id: null,
    county: "Huntingdon and Peterborough",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "huntingdon-and-peterborough",
    latitude: 52.34253,
    longitude: -0.18644,
    boundingBox: [0.27225, 52.36355, -0.10059, 52.32160],
    zoom: 13
  },
  {
    place_id: null,
    county: "Huntingdonshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "huntingdonshire",
    latitude: 52.37152,
    longitude: -0.22428,
    boundingBox: [1.59851, 52.70635, 1.14807, 52.03560],
    zoom: 9
  },
  {
    place_id: null,
    county: "Isle of Ely",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "isle-of-ely",
    zoom: 8
  },
  {
    place_id: null,
    county: "Isle of Wight",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "isle-of-wight",
    latitude: 50.67122,
    longitude: -1.32726,
    boundingBox: [2.01324, 50.84497, -0.63995, 50.49683],
    zoom: 10
  },
  {
    place_id: null,
    county: "Kent",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "kent",
    latitude: 51.20803,
    longitude: 0.74265,
    boundingBox: [0.63171, 51.55146, 2.11487, 50.86318],
    zoom: 9
  },
  {
    place_id: null,
    county: "Lancashire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "lancashire",
    latitude: 53.86287,
    longitude: -2.56481,
    boundingBox: [-5.31189, 54.50514, 0.18127, 53.20932],
    zoom: 9
  },
  {
    place_id: null,
    county: "Leicestershire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "leicestershire",
    latitude: 52.68590,
    longitude: -1.13083,
    boundingBox: [-2.50488, 53.01809, 0.24170, 52.35212],
    zoom: 9
  },
  {
    place_id: null,
    county: "Lincolnshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "lincolnshire",
    latitude: 53.18577,
    longitude: -0.29602,
    boundingBox: [3.04321, 53.83956, 2.44995, 52.52291],
    zoom: 8
  },
  {
    place_id: null,
    county: "Lincolnshire, Parts of Holland",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-holland",
    latitude: 52.85906,
    longitude: -0.00300,
    boundingBox: [-1.37604, 53.18958, 1.37054, 52.52625],
    zoom: 9
  },
  {
    place_id: null,
    county: "Lincolnshire, Parts of Kesteven",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-kesteven",
    latitude: 52.95029,
    longitude: -0.49713,
    boundingBox: [-1.87042, 53.28000, 0.87616, 52.61806],
    zoom: 8
  },
  {
    place_id: null,
    county: "Lincolnshire, Parts of Lindsey",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-lindsey",
    latitude: 53.36367,
    longitude: -0.30212,
    boundingBox: [3.04871, 54.01422, 2.44446, 52.70302],
    zoom: 8
  },

  /*TODO need to remove this*/
  {
    place_id: null,
    county: "London",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "london",
    latitude: 51.48914,
    longitude: -0.08789,
    boundingBox: [-0.69626, 51.75849, 0.52048, 51.21979],
    zoom: 9
  },
  // {
  //   place_id: null,
  //   county: "City of London",
  //   state_district: null,
  //   state: "England",
  //   country_code: "gb",
  //   url: "city-of-london",
  //   latitude: 51.51509,
  //   longitude: -0.09330,
  //   boundingBox: [-0.13621, 51.52578, -0.05038, 51.50442],
  //   zoom: 8
  // },
  {
    place_id: null,
    county: "Merseyside",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "merseyside",
    latitude: 53.49588,
    longitude: -2.92025,
    boundingBox: [-4.29291, 53.82173, -1.54633, 53.16818],
    zoom: 9
  },
  {
    place_id: null,
    county: "Middlesex",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "middlesex",
    latitude: 51.54637,
    longitude: -0.26283,
    boundingBox: [-0.94894, 51.71682, 0.42435, 51.37521],
    zoom: 10
  },
  {
    place_id: null,
    county: "Norfolk",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "norfolk",
    latitude: 52.67465,
    longitude: 0.94970,
    boundingBox: [-0.42297, 53.00652, 2.32361, 52.34037],
    zoom: 8
  },
  {
    place_id: null,
    county: "Northamptonshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "northamptonshire",
    latitude: 52.31184,
    longitude: -0.83771,
    boundingBox: [-2.21100, 52.64640, 0.53558, 51.97473],
    zoom: 9
  },
  {
    place_id: null,
    county: "Northumberland",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "northumberland",
    latitude: 55.30035,
    longitude: -2.07484,
    boundingBox: [-4.82300, 55.92151, 0.67017, 54.67065],
    zoom: 8
  },
  {
    place_id: null,
    county: "North Humberside",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "north-humberside",
    zoom: 8
  },
  {
    place_id: null,
    county: "North Yorkshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "north-yorkshire",
    latitude: 54.13670,
    longitude: -1.38977,
    boundingBox: [-4.13635, 54.77535, 1.35681, 53.48805],
    zoom: 8
  },
  {
    place_id: null,
    county: "Nottinghamshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "nottinghamshire",
    latitude: 53.14677,
    longitude: -1.00525,
    boundingBox: [-3.75183, 53.80065, 1.74133, 52.48278],
    zoom: 8
  },
  {
    place_id: null,
    county: "Oxfordshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "oxfordshire",
    latitude: 51.81541,
    longitude: -1.29639,
    boundingBox: [-4.04297, 52.48947, 1.45020, 51.13111],
    zoom: 8
  },
  {
    place_id: null,
    county: "Soke of Peterborough",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "soke-of-peterborough",
    zoom: 8
  },
  {
    place_id: null,
    county: "Rutland",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "rutland",
    latitude: 52.64223,
    longitude: -0.62485,
    boundingBox: [-1.31149, 52.80857, 0.06180, 52.47525],
    zoom: 10
  },
  {
    place_id: null,
    county: "Shropshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "shropshire",
    latitude: 52.65370,
    longitude: -2.73422,
    boundingBox: [-5.48218, 53.31447, 0.01099, 51.98150],
    zoom: 8
  },
  {
    place_id: null,
    county: "Somerset",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "somerset",
    latitude: 51.07765,
    longitude: -3.04321,
    boundingBox: [-4.41650, 51.42148, -1.66992, 50.73124],
    zoom: 9
  },
  {
    place_id: null,
    county: "South Humberside",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "south-humberside",
    zoom: 8
  },
  {
    place_id: null,
    county: "South Yorkshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "south-yorkshire",
    latitude: 53.48176,
    longitude: -1.34396,
    boundingBox: [-2.71637, 53.80714, 0.03021, 53.15336],
    zoom: 9
  },
  {
    place_id: null,
    county: "Staffordshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "staffordshire",
    latitude: 52.82659,
    longitude: -2.02807,
    boundingBox: [-4.77356, 53.48478, 0.71960, 52.15708],
    zoom: 8
  },
  {
    place_id: null,
    county: "Suffolk",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "suffolk",
    latitude: 52.24126,
    longitude: 1.05469,
    boundingBox: [-0.31860, 52.57635, 2.42798, 51.90361],
    zoom: 9
  },
  {
    place_id: null,
    county: "Surrey",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "surrey",
    latitude: 51.27197,
    longitude: -0.39536,
    boundingBox: [-1.76880, 51.61461, 0.97778, 50.92728],
    zoom: 9
  },
  {
    place_id: null,
    county: "Sussex",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "sussex",
    latitude: 50.94458,
    longitude: -0.04395,
    boundingBox: [-1.41724, 51.28941, 1.32935, 50.59719],
    zoom: 9
  },
  {
    place_id: null,
    county: "Tyne and Wear",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "tyne-and-wear",
    latitude: 54.93977,
    longitude: -1.59851,
    boundingBox: [-2.28516, 55.09723, -0.91187, 54.78168],
    zoom: 8
  },
  {
    place_id: null,
    county: "Warwickshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "warwickshire",
    latitude: 52.32283,
    longitude: -1.56707,
    boundingBox: [-4.31213, 52.98834, 1.18103, 51.64529],
    zoom: 8
  },
  {
    place_id: null,
    county: "West Midlands",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "west-midlands",
    latitude: 52.50550,
    longitude: -1.8154,
    boundingBox: [-2.50214, 52.67222, -1.12885, 52.33786],
    zoom: 8
  },
  {
    place_id: null,
    county: "Westmorland",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "westmorland",
    latitude: 54.45006,
    longitude: -2.74191,
    boundingBox: [-5.48767, 55.08466, 0.00549, 53.80714],
    zoom: 8
  },
  {
    place_id: null,
    county: "West Suffolk",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "west-suffolk",
    latitude: 52.25862,
    longitude: 0.65371,
    boundingBox: [-0.71960, 52.59304, 2.02698, 51.92056],
    zoom: 9
  },
  {
    place_id: null,
    county: "West Sussex",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "west-sussex",
    latitude: 50.94507,
    longitude: -0.45653,
    boundingBox: [-1.82922, 51.28941, 0.91736, 50.59719],
    zoom: 9
  },
  {
    place_id: null,
    county: "West Yorkshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "west-yorkshire",
    latitude: 53.74203,
    longitude: -1.68605,
    boundingBox: [-3.05969, 54.06584, -0.31311, 53.41608],
    zoom: 9
  },
  {
    place_id: null,
    county: "Wiltshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "wiltshire",
    latitude: 51.32563,
    longitude: -1.92566,
    boundingBox: [-4.67468, 52.00856, 0.81848, 50.63553],
    zoom: 8
  },
  {
    place_id: null,
    county: "Worcestershire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "worcestershire",
    latitude: 52.21160,
    longitude: -2.21031,
    boundingBox: [-3.58429, 52.54630, -0.83771, 51.87310],
    zoom: 9
  },
  {
    place_id: null,
    county: "Yorkshire",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "yorkshire",
    latitude: 53.74203,
    longitude: -1.6860,
    boundingBox: [-3.05969, 54.06584, -0.31311, 53.41608],
    zoom: 9
  },
  {
    place_id: null,
    county: "Yorkshire, East Riding",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "yorkshire-east-riding",
    zoom: 8
  },
  {
    place_id: null,
    county: "Yorkshire, North Riding",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "yorkshire-north-riding",
    zoom: 8
  },
  {
    place_id: null,
    county: "Yorkshire, West Riding",
    state_district: null,
    state: "England",
    country_code: "gb",
    url: "yorkshire-west-riding",
    zoom: 8
  },
];
